import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

function BeBoss() {
    return (
        <div className="BossHeader">
        <span className="LandingPageHeader" style={{ color: "#ffffff" }}>
         Ready to get started
        </span>
        <span style={{fontSize:'24px',display:'block',color:'white',margin:'9px 0'}}>Create your account today and start making your money work for you</span>
        <button
          className="BackButton"
          style={{
            color: "#f58730",
            fontSize: "20px",
          }}
          onClick={() => this.props.history.push("/Signup")}
        >
          Sign up for a free account
          
          <ArrowForwardIcon style={{ marginLeft: "10px" }} />
        </button>
      </div>
      
    )
}

export default BeBoss;
