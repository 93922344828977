import React, { Component } from "react";
import {
  Grid,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { validateEmail } from "../../Utilities/SharedFunctions";
import LoginModal from "../../Views/SignUp/LoginModal";

const useStyles = theme => ({
  button: {
    "&&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const themeInvest = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiButton: {
      textSecondary: {
        color: "#003C58 !important"
      },
      textPrimary: {
        color: "#1A1A1A"
      }
    },
    MuiSvgIcon: {
      root: {
        position: "inherit",
        fill: "#000000",
        width: "0.7em"
      }
    },
    MuiTypography: {
      body1: {
        fontFamily: "inherit"
      }
    },
    MuiCheckbox: {
      root: {
        color: "#2EB57E !important",
        "&&:hover": {
          backgroundColor: "transparent"
        },
        "&$checked": {
          color: "#2EB57E !important",
          "&&:hover": {
            backgroundColor: "transparent"
          }
        }
      }
    }
  }
});

class AddToInvestment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      validEmail: false,
      unsubscribe: false,
      setOpen: false
    };
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ setOpen: !this.state.setOpen });
  }

  handleFormInput(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === "email") {
      this.setState({ validEmail: validateEmail(event.target.value) });
    }
  }

  handleSaveCard = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  CapitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  handleCheckLogin() {
    var loginState = false;
    if (!loginState) {
      this.setState({ setOpen: true });
    } else {
      console.log("plugin payment gateway");
    }
  }

  render() {
    var CurrencyFormat = require("react-currency-format");
    var car = this.props.selectedCar;
    const { classes } = this.props;

    return (
      <div style={{ marginTop: "65px" }}>
        <div className="LandingPagePadding">
          <button
            className="BackButton"
            style={{ color: "#002AD1" }}
            onClick={() => this.props.handleView(1)}
          >
            <img
              src="/icon/backIcon.svg"
              alt="back"
              style={{ marginRight: "10px" }}
            />
            Back to details page
          </button>
          <div style={{ marginBottom: "43px", textAlign: "center" }}>
            <span className="Bold" style={{ fontSize: "20px" }}>
              You are about to add a car to your investment account{" "}
            </span>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div
                className="ShowroomCarSlides"
                style={{
                  background: `url(${car.MainImage})`
                }}
              ></div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="InvestmentMessageA" style={{ margin: "0px" }}>
                <div className="ShowroomCarDetailsA">
                  <span className="LandingPageBlackText Bold">{car.Name}</span>
                </div>
                <div className="DisplayFlexB">
                  <div className="ShowroomCarDetailsA">
                    <span className="LandingPageBlackText Bold">
                      Reg year:{" "}
                    </span>
                    <span className="GreyText" style={{ fontSize: "16px" }}>
                      {car.Year}
                    </span>
                  </div>
                  <div className="ShowroomCarDetailsA">
                    <span className="LandingPageBlackText Bold">Fuel: </span>
                    <span className="GreyText" style={{ fontSize: "16px" }}>
                      {this.CapitalizeFirstLetter(car.FuelType)}
                    </span>
                  </div>
                </div>
                <div className="DisplayFlexB">
                  <div className="ShowroomCarDetailsA">
                    <span className="LandingPageBlackText Bold">Mileage: </span>
                    <span className="GreyText" style={{ fontSize: "16px" }}>
                      {car.Year}
                    </span>
                  </div>
                  <div className="ShowroomCarDetailsA">
                    <span className="LandingPageBlackText Bold">
                      Transmission:{" "}
                    </span>
                    <span className="GreyText" style={{ fontSize: "16px" }}>
                      {this.CapitalizeFirstLetter(car.Transmission)}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="LandingPageBlackText Bold">Price: </span>
                  <span className="LandingPageBlackText Bold">
                    USD{" "}
                    <CurrencyFormat
                      value={parseFloat(car.CarPrice).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              </div>
              <div className="ShowroomButton" style={{ margin: "20px 0px" }}>
                <button
                  className="SignUpFormsSubmit"
                  onClick={() => this.handleCheckLogin()}
                >
                  Proceed to checkout
                </button>
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: "122px" }}>
            <div>
              <span className="LandingPageSubHeader">Privacy Notice</span>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <span className="LandingPageBlackText">
                We will use the details you have shared to manage your car
                purchase. You agree to the processing, storage, sharing and use
                of this information for the purpose of managing your car
                purchase as described in our
              </span>
              <button
                className="RedirectButton"
                style={{
                  fontSize: "16px",
                  color: "#18191f",
                  marginLeft: "5px"
                }}
              >
                Privacy Policy.
              </button>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <span className="LandingPageBlackText">
                We believe that based on this purchase you may be interested in
                other related products and services we offer. As described in
                our Privacy Policy we will use the information you have shared
                to send you communications about such products and services. If
                you do not wish to receive these communications from us then
                please check here and you will be unsubscribed from this
                activity.
              </span>
            </div>
            <MuiThemeProvider theme={themeInvest}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.unsubscribe}
                    className={classes.button}
                    onChange={this.handleSaveCard}
                    name="unsubscribe"
                    color="primary"
                  />
                }
                label="Unsubscribe from updates"
              />
            </MuiThemeProvider>
          </div>
        </div>
        <LoginModal handleOpen={this.handleOpen} setOpen={this.state.setOpen} />
      </div>
    );
  }
}

export default withStyles(useStyles)(AddToInvestment);
