import React, { Component } from "react";

class ModelFilter extends Component {
  render() {
    return (
      <div>
        <div className="Search" style={{ width: "100%" }}>
          <input
            id="searchModel"
            type="text"
            placeholder="Filter by name ..."
            autoComplete="off"
            value={this.props.searchModel}
            onChange={event =>
              this.props.handleSearch(event.target.id, event.target.value)
            }
          />
        </div>
        <div className="ModelContainerB">
          {this.props.model.map((model, index) => (
            <div
              className="OtherModels"
              key={index}
              onClick={() => this.props.handleSelectedModel(index)}
              style={
                this.props.selectedModel === model
                  ? { backgroundColor: "#002ad1" }
                  : null
              }
            >
              <span
                className="BlackText"
                style={
                  this.props.selectedModel === model
                    ? { fontSize: "16px", color: "#ffffff" }
                    : { fontSize: "16px" }
                }
              >
                {model.model}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ModelFilter;
