import React, { Component } from "react";
import {
  createMuiTheme,
  Select,
  MuiThemeProvider,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { numberCheck, validateEmail } from "../../Utilities/SharedFunctions";
import { CircularProgress, withStyles } from "@material-ui/core";
//import Successful from "./Successful";
import { post_request } from "../../Services/FetchFunctions";
import CustomSnackbar from "../SharedComponents/CustomSnackbar";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "../SharedComponents/Tooltip";

const MenuProps = {
  style: {
    marginTop: "15px",
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const CustomSwitch = withStyles({
  root: {
    width: 28,
    height: 18,
    padding: 0,
    marginRight: 10,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(10.5px)",
      color: "#ffffff",
      "& + $track": {
        backgroundColor: "#009fe4",
        opacity: 1,
        border: "none",
      },
    },
    // '&$focusVisible $thumb': {
    //   color: '#009fe4',
    //   border: '6px solid #fff'
    // }
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 26 / 2,
    border: "1px solid rgba(112, 112, 112, 0.4)",
    backgroundColor: "rgba(112, 112, 112, 0.4)",
    opacity: 1,
    // transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {},
})(Switch);

const customTopUp = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontFamily: "inherit",
      },
      select: {
        color: "rgba(37, 37, 37, 0.7)",
        "&:focus": {
          backgroundColor: "none",
        },
      },
      icon: {
        position: "unset",
        display: "none",
      },
      nativeInput: {
        width: "auto",
        display: "none !important",
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgba(37, 37, 37, 0.7)",
        fontFamily: "inherit",
        fontSize: "14px",
        height: "40px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f4f7ff !important",
        },
        "&$selected": {
          backgroundColor: "#f4f7ff",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    MuiInput: {
      root: {
        position: "inherit",
        height: "40px",
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "inherit",
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
        height: "40px",
        border: "1px solid rgba(37, 43, 51, 0.15)",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
        "&:focus": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
      },
      input: {
        padding: "0px",
        border: "none !important",
        boxShadow: "none !important",
        margin: "0px !important",
      },
    },
  },
});

class SendMoneyMpesa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      sourceAccount: "",
      phoneNumber: "",
      amount: "",
      selectedCurrency: "KES",
      selectedAccountFrom: "mainAccount",
      selectedAccountTo: "investmentAccount",
      availableCurrencies: ["KES", "USD", "GBP", "EUR"],
      verificationCode: ["", "", "", "", ""],
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      setOpen: false,
      accounts: [],
      investmentUser: [],
      mainAccountUser: [],
      transMessage: "",
      paymentRef: "",
      totalAmount: "0.00",
      charges: "",
      transDescription: "",
      anotherUser: false,
      anotherPhone: "",
      anotherEmail: "",
      validEmail: false,
      investmentAccount: [],
      mainAccount: [],
      incomeAccount:[],
      clientName: "",
      disabled: false
    };
    this.timer = null;

    this.handleFormInputNumber = this.handleFormInputNumber.bind(this);
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleVerificationInput = this.handleVerificationInput.bind(this);
  }

  componentDidMount() {
    var data = this.props.accounts;
    

    var inv = data.filter((account) => account.AccountType === "INVESTMENT");
    var main = data.filter((account) => account.AccountType === "MAIN");
    let income = data.filter((account) => account.AccountType === "INCOME");
   
    this.setState({
      accounts: data,
      investmentUser: inv,
      mainAccountUser: main,
      selectedCurrency: main[0].AccountCurrency,
      incomeAccount: income
    });
    if(this.state.selectedAccountFrom === "mainAccount"){
      this.setState({ selectedAccountTo: "investmentAccount"})
    }else {
      this.setState({ selectedAccountTo: "mainAccount"})
    }
  }

  handleView(view) {
    this.setState({ view: view });
  }

  handleFormInputNumber(event) {
    if (event.target.value.length > 0) {
      if (numberCheck(event.target.value)) {
        this.setState({ [event.target.id]: event.target.value });
      }
    } else {
      this.setState({ [event.target.id]: "" });
    }
  }

  handleFormInput(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
    if (event.target.id === "anotherPhone") {
      let phone = event.target.value;
      //calls getuseraccounts after user stops typing for 2 seconds
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getUserAccounts(phone);
      }, 2500);
    }
    if (event.target.id === "email") {
      this.setState({ validEmail: validateEmail(event.target.value) });
    }
  }
  getUserAccounts(phone) {
    let data = {
      account: phone,
    };
    post_request("client/account/funds-transfer/get-account", data).then(
      (resp) => {
        //console.log(resp);
        let accounts = resp.data.Accounts;
        let investmentAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        let mainAccount = accounts.filter(
          (account) => account.AccountType === "MAIN"
        );
       
        this.setState({
          mainAccount: mainAccount,
          investmentAccount: investmentAccount,
          clientName: resp.data.ClientName,
          
        });
      }
    );
  }

  handleSubmit() {
    this.setState({ isLoading: true });

    let userAccountNo = this.state.mainAccountUser[0]; //user's main account
    let userInvestmentAccountNo = this.state.investmentUser[0];
    let userIncomeAccNo = this.state.incomeAccount[0];
    let payload;
    if (this.state.anotherUser) {
      payload = {
        AccountFrom: userAccountNo.AccountNumber,
        AccountTo:
          this.state.selectedAccountTo === "investmentAccount"
            ? this.state.investmentAccount[0].AccountNumber
            : this.state.mainAccount[0].AccountNumber,
        Amount: this.state.amount,
        Description: this.state.transDescription,
      };
    } else {
      payload = {
       
        AccountFrom: this.state.selectedAccountFrom === "mainAccount"
       ? userAccountNo.AccountNumber
       : userIncomeAccNo.AccountNumber,
        AccountTo: this.state.selectedAccountTo === "mainAccount" ? userAccountNo.AccountNumber : userInvestmentAccountNo.AccountNumber  ,
        Amount: this.state.amount,
        Description: this.state.transDescription,
      };
    }
    ///console.log(payload);
    const url = "client/account/funds-transfer";
    post_request(url, payload)
      .then((resp) => {
        this.setState({
          isLoading: false,
          transMessage: resp.data.Message,
          paymentRef: resp.data.PaymentRef,
          totalAmount: resp.data.TotalAmount,
          charges: resp.data.Charges,
          view: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Could not process payment",
          responseStatus: "failed",
          snackbartxt: err.response.data.Message,
        });
      });
  }
  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  handleOpen() {
    this.setState({ setOpen: !this.state.setOpen });
  }
  handleVerificationInput(event) {
    var index = event.target.id;
    const newArray = Array.from(this.state.verificationCode);
    const nextSibling = document.querySelector(
      `input[name=ssn-${parseFloat(index, 10) + 1}]`
    );

    if (numberCheck(event.target.value)) {
      if (event.target.value.length > 1) {
        for (var i = 0; i < 5; i++) {
          newArray[i] = event.target.value.charAt(i);
        }
        document.querySelector(`input[name=ssn-4]`).focus();
      } else {
        newArray[index] = event.target.value;
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
      this.setState({ verificationCode: newArray });
    } else {
      newArray[index] = "";
      this.setState({ verificationCode: newArray });
    }
  }
  handleValidation() {
    var url = "client/account/funds-transfer/validate";
    var verificationCode = this.state.verificationCode
      .toString()
      .replace(/,/g, "");
    var payload = {
      code: verificationCode,
    };
    post_request(url, payload)
      .then((resp) => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Transaction successful",
          responseStatus: "success",
          snackbartxt: resp.data.Message,
        });
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Could not process payment",
          responseStatus: "failed",
          snackbartxt: err.response.data.Message,
        });
      });
  }
  handleSwitch() {
    this.setState({ anotherUser: !this.state.anotherUser });
  }
  // +z./aUser should click send button once and have it disabled to avoid them sending money severally
handleClick = (event) => {
  if (this.state.disabled) {
      return;
  }else{
    this.setState({disabled: true});
   this.handleValidation(); 
  }
      
}
  handleKeyPress(e) {
    if (e.target.id === "4") {
      if (e.key === "Enter") {
        this.handleVerification();
      }
    } else {
      if (e.key === "Enter") {
        this.handleSubmit();
      }
    }
  }

  render() {
   
    var CurrencyFormat = require("react-currency-format");
    var inv = this.state.mainAccountUser[0];
    let income = this.state.incomeAccount[0];
    let incomebalance
    if (inv !== undefined) {
      var balance = inv.AccountBalance;
      var curr = inv.AccountCurrency;
    }
    if (income !== undefined) {
       incomebalance = income.AccountBalance;
    }
    let accountFromName =
      this.state.selectedAccountFrom === "mainAccount"
        ? "Main Account"
        : (this.state.selectedAccountFrom === "incomeAccount" ? "Income Account" : "Investment Account" );
    let accountToName =
      this.state.selectedAccountTo === "investmentAccount"
        ? "Investment Account"
        : "Main Account";

    return (
      <div>
        <Tooltip handleOpen={this.handleOpen} setOpen={this.state.setOpen} />
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <div
          style={{ marginTop: "100px", paddingLeft: "20%" }}
          className="ButtonPadding"
        >
          <button
            className="BackButton"
            onClick={() => {
              this.state.view === 0
                ? this.props.handleView(0)
                : this.handleView(0);
            }}
          >
            <img src="/icon/back.svg" alt="back" />
            <span>Back</span>
          </button>
        </div>
        {this.state.view === 0 && (
          <div className="ContentSpace">
            <span
              className="MainContentHeader"
              style={{
                display: "block",
                margin: "0 auto",
                textAlign: "center",
                width: "440px",
              }}
            >
              This feature is used to transfer funds internally, within Java
              self drive accounts. Either to yourself or another Java Self Drive
              user client.
            </span>
            <div
              style={{
                padding: "15px",
                backgroundColor: "white",
                width: "400px",
              }}
              className="PersonalInfo PaymentsForm"
            >
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Send money
                </span>

                <div className="SendMoneyBalance">
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <img
                      style={{ marginRight: "5px" }}
                      src="/icon/entypo_wallet.svg"
                      alt="wallet"
                    />
                    <span className="MainContentSubheader">
                      {accountFromName} balance
                    </span>
                    <div onClick={() => this.handleOpen()}>
                      <HelpOutlineIcon className="tip" />
                    </div>
                  </div>
                  <span className="BlueBold">
                    {curr}{" "}
                    {this.state.selectedAccountFrom === "mainAccount" ? (
                      <CurrencyFormat
                      value={parseFloat(balance).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    ) : (<CurrencyFormat
                      value={parseFloat(incomebalance).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />) }
                  </span>
                  <span className="MainContentSubheader">Actual balance </span>
                  <span className="Bold">
                    {curr}{" "}
                    <CurrencyFormat
                      value={parseFloat(balance).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />{" "}
                  </span>
                </div>
              </div>
              <MuiThemeProvider theme={customTopUp}>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                    <CustomSwitch
                      name="anotherUser"
                      checked={this.state.anotherUser}
                      onChange={() => this.handleSwitch()}
                    />
                    <label className="formLabel" style={{ display: "block" }}>
                      I want to send to another person
                    </label>
                  </div>

                  {this.state.anotherUser ? (
                    <>
                      {/* sending money from client to another user, must be from the client's MAIN a/c */}
                      {curr === "USD" ? (
                        <>
                          <label>
                            Enter receipient's email address or phone number
                          </label>
                          <input
                            className="formInput"
                            id="anotherPhone"
                            type="text"
                            value={this.state.anotherPhone}
                            onChange={this.handleFormInput}
                            required
                          />
                        </>
                      ) : (
                        <>
                          <label
                            className="formLabel"
                            style={{ display: "block", marginTop: "20px" }}
                          >
                            Enter receipient's phone number or email addresss
                          </label>
                          <input
                            className="formInput"
                            id="anotherPhone"
                            type="text"
                            placeholder="254700000000"
                            value={this.state.anotherPhone}
                            onChange={this.handleFormInput}
                            required
                          />
                        </>
                      )}

                      <label className="formLabel" style={{ display: "block" }}>
                        Select the account you'd like to deposit to
                      </label>
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                          paddingLeft: "15px",
                          textAlign: "left",
                        }}
                        name="selectedAccountTo"
                        value={this.state.selectedAccountTo}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={"investmentAccount"}>
                          Investment account
                        </MenuItem>
                        <MenuItem value={"mainAccount"}>Main Account</MenuItem>
                      </Select>
                    </>
                  ) : (
                    <>
                      {/* client sending money to another a/c (theirs)  */}
                      <div style={{ marginTop: "20px" }}>
                        <span
                          style={{ display: "block", marginBottom: "10px" }}
                          className="MainContentSubheader"
                        >
                          You’ll will receive a code on your phone to validate
                          this transaction{" "}
                        </span>
                      </div>

                      <label
                        className="formLabel"
                        style={{ display: "block", marginTop: "20px" }}
                      >
                        Select the account you'd like to withdraw from
                      </label>
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                          paddingLeft: "15px",
                          textAlign: "left",
                        }}
                        name="selectedAccountFrom"
                        value={this.state.selectedAccountFrom}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        
                        <MenuItem value={"mainAccount"}>Main Account</MenuItem>
                        <MenuItem value={"incomeAccount"}>Income Account</MenuItem>
                        
                      </Select>
                      <label className="formLabel" style={{ display: "block" }}>
                        Select the account you'd like to deposit to
                      </label>
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                          paddingLeft: "15px",
                          textAlign: "left",
                        }}
                        name="selectedAccountTo"
                        value={this.state.selectedAccountTo}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={"investmentAccount"}>
                          Investment account
                        </MenuItem>
                        <MenuItem disabled={this.state.selectedAccountFrom === "incomeAccount" ? false : true} value={"mainAccount"}>Main Account</MenuItem>
                      </Select>
                    </>
                  )}

                  <label className="formLabel" style={{ display: "block" }}>
                    How much would you like to send?
                  </label>

                  
                  <div className="SignUpFormsTwoInOne">
                    <div className="SignUpFormsTwoInOneSmall">
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                        }}
                        name="selectedCurrency"
                        value={this.state.selectedCurrency}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        {this.state.availableCurrencies.map((code, index) => (
                          <MenuItem value={code} key={index}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <input
                      className="formInput"
                      id="amount"
                      type="text"
                      autoComplete="off"
                      maxLength="9"
                      value={this.state.amount}
                      onChange={this.handleFormInputNumber}
                      required
                    />
                  </div>

                  <label className="formLabel">
                    Reason for transaction(Narration)
                  </label>
                  <input
                    className="formInput"
                    id="transDescription"
                    type="text"
                    value={this.state.transDescription}
                    onChange={this.handleFormInput}
                    required
                  />

                  <button
                    className={
                      //this.state.phoneNumber !== "" &&
                      this.state.amount !== ""
                        ? "SignUpFormsSubmit"
                        : "SignUpFormsSubmitDisabled"
                    }
                    disabled={
                      //this.state.phoneNumber !== "" &&
                      this.state.amount !== "" ? false : true
                    }
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Confirm transaction"
                    )}
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        )}
        {this.state.view === 1 && (
          <div className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  {this.state.transMessage}{" "}
                </span>
                <span className="MainContentSubheader">
                  Confirm the transaction details and enter the code sent to
                  your phone below
                </span>

                <div style={{ height: "235px" }} className="SendMoneyBalance">
                  <span className="MainContentSubheader">You are sending</span>

                  <span
                    className="BlueBold"
                  >
                    {curr}{" "}
                    <CurrencyFormat
                      value={parseFloat(this.state.totalAmount).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                  <br />
                  {this.state.anotherUser ? (
                    <div style={{ marginBottom: "20px" }}>
                      <span className="MainContentSubheader">
                        From your <b>{accountFromName}</b>
                      </span>
                      <span
                        style={{ display: "block" }}
                        className="MainContentSubheader"
                      >
                        To another receipient{" "}
                        <b>
                          {this.state.clientName}'s{" "}
                          {this.state.selectedAccountTo === "investmentAccount"
                            ? "Investment Account"
                            : "Main Account"}
                        </b>
                      </span>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "20px" }}>
                      <span className="MainContentSubheader">
                        From your <b>{accountFromName}</b>
                      </span>
                      <span
                        style={{ display: "block" }}
                        className="MainContentSubheader"
                      >
                        To your <b>{accountToName} </b>
                      </span>
                    </div>
                  )}

                 
                  <span className="MainContentSubheader">
                    Transaction fee: {this.state.charges}
                  </span>
                  <span
                    style={{ display: "block" }}
                    className="MainContentSubheader"
                  >
                    Transaction Reference Number: {this.state.paymentRef}
                  </span>
                </div>
                <div className="SignFormDetails">
                  <div
                    className="DisplayFlexSpace"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="Verification">
                      <input
                        name="ssn-0"
                        id="0"
                        type="text"
                        autoComplete="off"
                        value={this.state.verificationCode[0]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-1"
                        id="1"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[1]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-2"
                        id="2"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[2]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-3"
                        id="3"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[3]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-4"
                        id="4"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[4]}
                        onChange={this.handleVerificationInput}
                        onKeyPress={(e) => {
                          this.handleKeyPress(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <button
                  className={this.state.disabled ? "SignUpFormsSubmitDisabled" : "SignUpFormsSubmit"}
                  disabled={this.state.disabled}
                  onClick={() => this.handleClick()}
                >
                  {this.state.isLoading ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    "Send money"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default SendMoneyMpesa;
