import React, { Component } from "react";
import Background from "../../Styles/icon/Accountbalances.svg";
import { Grid } from "@material-ui/core";

class AccountBalances extends Component {
  render() {
    var account = this.props.accountInv;
    var accountMain = this.props.accountMain;
    let incomeAcc = this.props.income
    let CurrencyFormat = require("react-currency-format");

    return (
      <div
        className="Cards AccountBalances"
        style={{ background: `url(${Background})`, padding:'18px 25px' }}
      >
        <Grid container>
          
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="DisplayFlex1">
            <span style={{ textTransform: "uppercase" }} className="WhiteText">
              {this.props.name}
            </span>
              <div>
                <span className="WhiteText" style={{ fontWeight: "normal" }}>
                  A/c ID: {account.AccountNumber}
                </span>
              </div>
              <img src="/icon/refresh.svg" alt="refresh" />
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop:'15px' }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <span
              className="WhiteText"
              style={{ color: " rgba(245, 249, 254, 0.63)" }}
            >
              Main Account Balance
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <span className="WhiteText">
              {" "}
              {account.AccountCurrency}{" "}
              <CurrencyFormat
                value={parseFloat(accountMain).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop:'15px' }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <span
              className="WhiteText"
              style={{ color: " rgba(245, 249, 254, 0.63)" }}
            >
              Investment Account Balance
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <span className="WhiteText">
              {" "}
              {account.AccountCurrency}{" "}
              <CurrencyFormat
                value={parseFloat(account.AccountBalance).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop:'15px' }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <span
              className="WhiteText"
              style={{ color: " rgba(245, 249, 254, 0.63)" }}
            >
              Income Account Balance
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <span className="WhiteText">
              KES{" "}
              <CurrencyFormat
                value={parseFloat(incomeAcc).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AccountBalances;
