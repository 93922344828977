import React, { Component } from "react";

class InvestmentPlan extends Component {
  render() {
    var CurrencyFormat = require("react-currency-format");

    return (
      <div>
        <span style={{ fontSize: "20px" }}>
          {this.props.type} invesment plan
        </span>
        <br />
        <img src="/icon/plan.svg" alt="plan" />
        <div style={{ fontSize: "16px", marginTop: "18px" }}>
          <span>{this.props.percentage} of total value of car</span>
        </div>
        <div style={{ fontSize: "26px", marginTop: "7px" }}>
          <span>
            {this.props.currency}{" "}
            <CurrencyFormat
              value={parseFloat(this.props.amount).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </span>
        </div>
        <button
          className="RedirectButton"
          style={{ marginTop: "22px", color: "#f5f9fe" }}
        >
          Learn more
        </button>
        <div style={{ marginTop: "30px" }}>
          <button className="SignUpFormsSubmit">Continue with plan</button>
        </div>
      </div>
    );
  }
}

export default InvestmentPlan;
