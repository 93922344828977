import React, { Component } from "react";
import { Grid, withStyles, Paper } from "@material-ui/core";
import TopUpBank from "./TopUpBank";
import TopUpMpesa from "./TopUpMpesa";
import TopUpPaypal from "./TopUpPaypal";
import { getRequest } from "../../Services/FetchFunctions";

const styles = theme => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",

    border: "solid 1px #f5f9fe",
    backgroundColor: "#f5f9fe",
    textAlign: "center",
    color: "rgba(37, 37, 37, 0.7)",
    cursor: "pointer",
    "&:hover": {
      border: "solid 1px #f58730"
    },
    "&:focus": {
      border: "solid 1px #f58730"
    }

  },
  root: {
    maxWidth: 400,
    flexGrow: 1
  }
});

class TopUp extends Component {
  constructor() {
    super();
    this.state = {
      view: 0,
      accounts:[],
      investmentAccount:[],
      clientDets:[],
      clientPhone:""
    };
    this.handleView = this.handleView.bind(this);
  }

  componentDidMount(){
    this.getAccounts();
    //console.log(this.props.data);
    var data = this.props.data;
    this.setState({ clientPhone: data.phoneNumber});
  }

  handleView(view) {
    this.setState({ view: view });
  }
  getAccounts(){
      const url = "client/account/get-accounts";
      getRequest(url)
        .then((resp) => {
          var accounts = resp.data.List;
          var filteredAccount = accounts.filter(
            (account) => account.AccountType === "INVESTMENT"
          );
          this.setState({
            accounts: resp.data.List,
            investmentAccount: filteredAccount
          });
        })
        .catch((err) => {
          console.log(err);
        });
  } 
  getDets(){
    var endpoint = "client/get-details";
    getRequest(endpoint).then(resp=> {
      this.setState({ clientDets:resp.data})
    }).catch(err=>{
      console.log(err);
    })
  }

  render() {
    const { classes } = this.props;
  // console.log(this.state.clientPhone);
    var account = this.state.investmentAccount[0];
    if (account !== undefined) {
      var curr = account.AccountCurrency;
    }
    //console.log(curr);
   // var curr = account. !== undefined ? account.AccountCurrency : '';
    return (
      <div className="Content">
        { this.state.view === 0 && (
          <div>
           <div className="ButtonPadding">
           <button
             className="BackButton"
             onClick={() => {
               this.state.view === 0
                 ? this.props.handleView(0)
                 : this.handleView(0);
             }}
           >
             <img src="/icon/back.svg" alt="back" />
             <span>Back</span>
           </button>
         </div>
         <div className="ContentSpace">
              <div className="PaymentsView">
                <div
                  className="ContentHeader"
                  style={{ textAlign: "center", marginBottom: "35px" }}
                >
                  <span
                    className="MainContentHeader Bold"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    Top-up Investment Kit
                  </span>
                  <span className="MainContentSubheader">
                    Select from the various options on top-up of your account
                    wallet
                  </span>
                </div>
                <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={curr === 'KES' ?  () => this.handleView(2) : null}
                    >
                      <div className="PaymentMethod">
                        <img
                          src="/icon/mpesa.svg"
                          alt="MPESA"
                          style={{ marginTop: "10px" }}
                        />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        >
                          MPESA account
                        </span>
                        <span className="GreyText">
                          Enter your phone number and PIN when prompted, to
                          authorize the transaction
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={ curr === 'USD' ?  () => this.handleView(3) : null }
                      
                    >
                      <div className="PaymentMethod">
                        <img
                          src="/icon/Paypal.svg"
                          alt="paypal"
                          style={{ marginTop: "10px" }}
                        />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        >
                          PayPal
                        </span>
                        <span className="GreyText">
                        Top-up directly from your Paypal account
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={() => this.handleView(1)}
                    >
                      <div className="PaymentMethod">
                        <img src="/icon/bank.svg" alt="Bank" />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        >
                          Bank
                        </span>
                        <span className="GreyText">
                          Enter your bank details and make a transfer to your
                          wallet account
                        </span>
                        <span className="ComingSoon">
                         COMING SOON
                        
                        </span>
                        
                      </div>
                    </Paper>
                  </Grid> */}

                  
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                    >
                      <div className="PaymentMethod">
                        <img src="/icon/card.svg" alt="Card" />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        >
                          Card
                        </span>
                        <span className="GreyText">
                          Use your Debit or credit card to top-up the wallet.
                        </span>
                        <span className="ComingSoon">
                         COMING SOON
                        </span>


                      </div>
                    </Paper>
                  </Grid> */}
                </Grid>
              </div>
            </div>

         </div>
        )}
        { this.state.view === 1 && (
           <TopUpBank handleView={this.handleView} />
        )}
  { this.state.view === 2 && (
           <TopUpMpesa clientData={this.state.clientPhone} handleView={this.handleView} />
        )}
        { this.state.view === 3 && (
           <TopUpPaypal handleView={this.handleView} />
        )}

          </div>
        
    );
  }
}

export default withStyles(styles)(TopUp);
