import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { Component } from "react";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';


export class GoogleMap extends Component {
  constructor() {
    super();
    this.state = {
      openPopUp: false,
      moreDetails: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
    // this.handlePopUp = this.handlePopUp.bind(this);
    this.handlePopUpClose = this.handlePopUpClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  handleClick() {
    this.props.handleClick(this.props.data);
  }

  handlePopUp() {
    this.setState({ openPopUp: true });
  }

  handlePopUpClose() {
    this.setState({ openPopUp: false });
  }

  handleHover = () => {
    this.props.handlePopUp();
  };

  onMapClicked = (props) => {
    if (this.state.openPopUp) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        openPopUp: false,
      });
    }
  };
  displayPopUp() {
    var moreDets = this.props.data;
    //console.log(moreDets)
    return (
      <div className="FleetMoreDetails">
        {/* <img
          src="./icon/close.svg"
          alt="x"
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => this.setState({ openPopUp: false })}
        /> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="./icon/hatchCar.svg"
            alt="car"
            style={{ marginRight: "20px" }}
          />
          <div>
            <span>{this.props.carPlate}</span>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "100px" }} className="BlackText">
              Speed
            </span>
            <span className="GreyText">{moreDets.Speed}</span>
          </div>
          {/* <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Status
            </span>
            <span className="GreyText">{moreDets.VStatus}</span>
          </div> */}
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Battery
            </span>
            <span className="GreyText">{moreDets.Battery}</span>
          </div>

          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Altitude
            </span>
            <span className="GreyText">{moreDets.Altitude}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Time
            </span>
            <span className="GreyText">{moreDets.Time}</span>
          </div>
         
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Latitude
            </span>
            <span className="GreyText">{moreDets.Latitude}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Longitude
            </span>
            <span className="GreyText">{moreDets.Longitude}</span>
          </div>
          </div>
        {/* <div
          className={
            moreDets.VStatus === "Moving"
              ? "InProgress"
              : moreDets.VStatus === "Parked "
              ? "Completed"
              : "Idle"
          }
          style={{ float: "right" }}
        >
          {moreDets.VStatus}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="./icon/hatchCar.svg"
            alt="car"
            style={{ marginRight: "20px" }}
          />
          <div>
            <span>{this.props.carPlate}</span>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <span className="GreyText">{moreDets.Location}</span>
        </div>
        <div>
          <button
            style={{
              marginTop: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="SignUpFormsSubmit"
            onClick={this.handleClick}
          >
            View Details
            <ArrowForwardIcon style={{ marginLeft: "5px" }} />
          </button>
        </div> */}
      </div>
    );
  }

  render() {
    var info = this.props.data ? this.props.data : [];
    //var numberPlate = this.props.carPlate;
    var longitude = 0;
    var latitude = 0;
    //console.log(process.env.REACT_APP_GOOGLE_MAPS_API);
    if (Object.keys(info).length > 0) {
      latitude = parseFloat(info.Latitude.trim());
      longitude = parseFloat(info.Longitude.trim());
    }
    var center = {
      lat: latitude,
      lng: longitude,
    };

  
    return (
      <>
        {this.state.openPopUp ? this.displayPopUp() : null}
        <Map
          google={this.props.google}
          style={this.props.styles}
          center={center}
          initialCenter = {{
            lat:-1.286389,
            lng:36.817223
          }}
          onClick={this.onMapClicked}
          disableDoubleClickZoom
          scrollToWheel 
          zoom={14}
        >
          <Marker
            position={center}
            //onMouseover 
            onClick={() => this.setState({ openPopUp: true })}
            icon={{ url:'/icon/thecar.png' }}
          />
        </Map>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCS3SFgAlKlVEWwIZLU65MyveioIYIF-UQ",
})(GoogleMap);
