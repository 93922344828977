import React, { Component } from "react";

class MakeFilter extends Component {
  render() {
    return (
      <div>
        <div className="Search" style={{ width: "100%" }}>
          <input
            id="searchMake"
            type="text"
            placeholder="Filter by name ..."
            autoComplete="off"
            value={this.props.searchMake}
            onChange={event =>
              this.props.handleSearch(event.target.id, event.target.value)
            }
          />
        </div>
        <div className="ModelContainerB">
          {this.props.make.map((make, index) => (
            <div
              className="OtherModels"
              key={index}
              onClick={() => this.props.handleSelectedMake(index)}
              style={
                this.props.selectedMake === make
                  ? { backgroundColor: "#002ad1" }
                  : null
              }
            >
              <span
                className="BlackText"
                style={
                  this.props.selectedMake === make
                    ? { fontSize: "16px", color: "#ffffff" }
                    : { fontSize: "16px" }
                }
              >
                {make.make}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MakeFilter;
