import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  Modal,
  Backdrop,
  MuiThemeProvider
} from "@material-ui/core";
import { withRouter } from "react-router-dom";


const stylesModal = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "inherit"
  },
  container: {
    minHeight: 450,
    overflowX: "hidden"
  }
});

const themeModal = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5",
        backdropFilter: "blur(2px)"
      }
    }
  }
});

class Tooltip extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      completed: 5,
      buffer: 10,
      title: "",
      message: "",
      success: false,
      show: false
      
    };

    //this.handleFormInput = this.handleFormInput.bind(this);
    
  }

  

  render() {
    const { classes } = this.props;
    return (
      <div>
       
        <MuiThemeProvider theme={themeModal}>
          <Modal
            className={classes.modal}
            open={this.props.setOpen}
            onClose={() => this.props.handleOpen()}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <div className={classes.paper}>
              <div style={{ height: "255px" }}  className="ModalPaper">
                <div className="ModalHeader" style={{ padding: "0px" }}>
                  <div>
                    <button
                      className="BackButton"
                      style={{ float: "right" }}
                      onClick={() => this.props.handleOpen()}
                    >
                      <img src="./icon/close.svg" alt="x" />
                    </button>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "inline-block",
                      marginTop: "25px"
                    }}
                  >
                    <span
                      className="MainContentHeader"
                      style={{ fontSize: "20px" }}
                    >
                      How is available balance is calculated?{" "}
                    </span>
                    <br />
                    <span className="MainContentSubheader">
                    Available Balance = Actual balance (Account balance) + Overdraft/Advances - Locked funds
                    </span>
                  </div>
                </div>
                
                
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "rgba(37, 37, 37, 0.7)"
                  }}
                >
                  <button
                    className="SignUpFormsSubmit"
                    onClick={() => this.props.handleOpen()}
                  >
                   Okay
                  </button>
                </div>
              
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withRouter(withStyles(stylesModal)(Tooltip));
