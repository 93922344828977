import React, { Component } from "react";
import GoogleMap from "./GoogleMap";

const mapStyles = {
  width: "875px",
  height: "100%",
  position: "absolute",
};
class FleetMoreDetails extends Component {
  constructor() {
    super();
    this.state = {
      openPopUp: false,
      moreDetails: [],
    };
    this.handlePopUp = this.handlePopUp.bind(this);
    this.handlePopUpClose = this.handlePopUpClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handlePopUp() {
    this.setState({ openPopUp: true });
  }

  handlePopUpClose() {
    this.setState({ openPopUp: false });
  }

  handleClick() {
    this.props.handleOpen(this.props.carDetails);
  }

  displayPopUp() {
    var data = this.props.carDetails;

    var moreDets = this.props.moreDetails ? this.props.moreDetails : [];
    return (
      <div className="FleetMoreDetails">
        <img
          src="./icon/close.svg"
          alt="x"
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => this.setState({ openPopUp: false })}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="./icon/hatchCar.svg"
            alt="car"
            style={{ marginRight: "20px" }}
          />
          <div>
            <span>{data.NumberPlate}</span>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "100px" }} className="BlackText">
              Speed
            </span>
            <span className="GreyText">{moreDets.Speed}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Status
            </span>
            <span className="GreyText">{moreDets.VStatus}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Battery
            </span>
            <span className="GreyText">{moreDets.Battery}</span>
          </div>

          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Altitude
            </span>
            <span className="GreyText">{moreDets.Altitude}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Time
            </span>
            <span className="GreyText">{moreDets.Time}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Odometer
            </span>
            <span className="GreyText">{moreDets.Odometer}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Location
            </span>
            <span className="GreyText">{moreDets.Location}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Latitude
            </span>
            <span className="GreyText">{moreDets.Latitude}</span>
          </div>
          <div className="FleetSpanDivB">
            <span style={{ width: "100px" }} className="BlackText">
              Longitude
            </span>
            <span className="GreyText">{moreDets.Longitude}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // console.log(this.props.moreDetails)
    return (
      <div style={{ background: "rgba(0, 42, 209, 0.05)",minHeight:'600px' }}>
        {/* {this.state.openPopUp ? this.displayPopUp() : null} */}
        {/* <MapContainer
          data={this.props.moreDetails ? this.props.moreDetails : []}
          carPlate={
            this.props.carDetails ? this.props.carDetails.NumberPlate : ""
          }
          handlePopUp={this.handlePopUp}
          handlePopUpClose={this.handlePopUpClose}
          handleClick={this.handleClick}
        /> */}
        <GoogleMap
          data={this.props.moreDetails ? this.props.moreDetails : []}
          carPlate={
            this.props.carDetails ? this.props.carDetails.NumberPlate : ""
          }
          styles={mapStyles}
          handlePopUp={this.handlePopUp}
          handlePopUpClose={this.handlePopUpClose}
          handleClick={this.handleClick}
        />
      </div>
    );
  }
}

export default FleetMoreDetails;
