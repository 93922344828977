import React, { Component } from "react";
//import Loader from "../../Components/SharedComponents/Loader";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import { Grid, withStyles, Paper } from "@material-ui/core";
import FleetTable from "../../Components/FleetManagement/FleetTable";
import Search from "../../Components/SharedComponents/Search";
import FleetMoreDetails from "../../Components/FleetManagement/FleetMoreDetails";
import VehicleDetails from "../../Components/FleetManagement/VehicleDetails";
import { getRequest } from "../../Services/FetchFunctions";
//import NoData from "../../Components/SharedComponents/NoData";
//import GoogleMap from "../../Components/FleetManagement/GoogleMap";

const styles = (theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px transparent",
    backgroundColor: "#ffffff",
    minHeight: "100vh",
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

class FleetManagement extends Component {
  constructor() {
    super();
    this.state = {
      vehicleDets: [
        // {
        //   MainImage: "https://stage-backend.javaselfdrive.com/vehicle/images/6810bf28d7aae876857d2f026b1502b01628325425.jpg",
        //     NumberPlate: "KDA 825H",
        //     Year: 2014,
        //     vehicleDetails: {
        //         Speed: "0",
        //         FuelLevel: "19.5",
        //         VStatus: "Parked ",
        //         Battery: 96,
        //         Time: "20 Aug 2021 08:58",
        //         Odometer: "66964",
        //         Latitude: "-1.2886266",
        //         Longitude: "36.9049666",
        //         Altitude: "1618",
        //         Location: ""
        //     },
        //     Model: "CS5",
        //     VehicleName: "Mazda CX5",
        //     Make: "Mazda",
        //     Id: "1023",
        //     CurrentLocation: "Kilimani",
        //     VehicleStatus: "COMPLETED"
        // }
      ],
      vehicleStatus: [],
      moreDetails: {
        Battery: 9,
        Time: "16 May 2021 16:40",
        Odometer: "114000 Km",
        Latitude: "-1.286389",
        Longitude: "36.817223",
        Speed: "0 km/h",
        VStatus: "Parked",
        Altitude: "60",
        Location: "Kilimani Building",
        FuelLevel: "3.0",
      },
      searchItem: "",
      selected: [],
      viewMore: true,
      isLoading: false,
      cardID: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.handleFetchVehicles();
  }

  handleSearch(searchItem) {
    this.setState({ searchItem: searchItem });
  }

  handleFetchVehicles() {
    this.setState({ isLoading: true });
    var endpoint = "client/vehicle/list";
    getRequest(endpoint)
      .then((resp) => {
        //console.log(resp)
        this.setState({ vehicleDets: resp.data.List, isLoading: false });
        if (resp.data.List[0] !== undefined) {
          this.handleSelected(resp.data.List[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleOpen(carData) {
    this.setState({ selected: carData, viewMore: true });
    this.handleSelected(carData);
  }

  handleSelected(carData) {
    this.setState({ selected: carData });
    var vehID = carData.Id;
    var vehicleURL = `client/vehicle/status/${vehID}`;

    getRequest(vehicleURL).then((resp) => {
      console.log(resp.data.Data);
      this.setState({ moreDetails: resp.data.Data.vehicleDetails });
    });
  }

  handleClose() {
    this.setState({ viewMore: false });
  }

  render() {
    const { classes } = this.props;
    var fleet2 = this.state.vehicleDets.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val === "string" &&
          val.toLowerCase().includes(this.state.searchItem.toLowerCase())
      )
    );
    var selected = this.state.selected;
    var theselected;
    if (selected !== undefined) {
      theselected = selected;
    } else {
      theselected = this.state.vehicleDets[0];
    }

    return (
      <div>
        <div className="ContainerApp">
          <TopBar />
          <div className="MainBody">
            <div className="Menu">
              <Menu />
            </div>
            <div className="ContentFull">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Paper className={classes.paper} style={{ height: "100%" }}>
                    <div className="FleetManagementTableCard">
                      <div className="ContentHeader">
                        <span className="MainContentHeader Bold">
                          Fleet management
                        </span>
                        <br />
                        <span className="MainContentSubheader">
                          Select on a vehicle below to view status and location
                        </span>
                      </div>
                      <div
                        style={{
                          width: "220px",
                          background: "#fafafa",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          height: "50px",
                          marginTop: "20px",
                          fontSize: "14px",
                        }}
                      >
                        Total vehicles in fleet{" "}
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          {fleet2.length}
                        </span>
                      </div>
                      <div>
                        <div className="FilterDiv">
                          <img
                            src="/icon/filter.svg"
                            alt="filter"
                            style={{ marginRight: "10px" }}
                          />
                          <span className="BlackText">Filters</span>
                        </div>
                        <div
                          style={{ marginTop: "10px", marginBottom: "30px" }}
                        >
                          <Search
                            searchItem={this.state.searchItem}
                            placeholder={
                              "Search by vehicle name or number plate"
                            }
                            handleSearch={this.handleSearch}
                          />
                        </div>
                        <FleetTable
                          fleet={fleet2}
                          handleSelected={this.handleSelected}
                          selected={
                            theselected.length === 0 ? fleet2[0] : theselected
                          }
                          handleOpen={this.handleOpen}
                        />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  {this.state.viewMore ? (
                    <VehicleDetails
                      carDetails={
                        Object.keys(this.state.selected).length === 0
                          ? fleet2[0]
                          : this.state.selected
                      }
                      moreDetails={
                        Object.keys(this.state.moreDetails).length === 0
                          ? []
                          : this.state.moreDetails
                      }
                      handleClose={this.handleClose}
                    />
                  ) : (
                    <Paper className={classes.paper} style={{ height: "100%" }}>
                      <FleetMoreDetails
                        carDetails={
                          theselected.length === 0 ? fleet2[0] : theselected
                        }
                        moreDetails={
                          Object.keys(this.state.moreDetails).length === 0
                            ? []
                            : this.state.moreDetails
                        }
                        handleOpen={this.handleOpen}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>
              {/* </>
                )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FleetManagement);
