import React from "react";
import AppMenu from "../../Components/LandingPage/menu";
import { Grid } from "@material-ui/core";
import MoneyWork from "../../Components/LandingPage/MoneyWork";
import "aos/dist/aos.css";
import BeBoss from "../../Components/LandingPage/BeBoss";
import LandingPageFooter from "../../Components/LandingPage/Footer";

const process = [
  {
    title: "Checkout and Payment",
    text: "We begin by registering and signing up for an account, and visiting the explore deals page, to  view all avaiable investment deals with Java Self Drive. The vehicles can be foreign or locally used,one can choose their prefered car, based on its specification or other vehicle details. You can also choose more than one car to add to cart for later checkout. The totals are calculatedand displayed during checkout.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Saving to a target amount",
    text: "In the event you might not have the full amount, we encourage you to deposit funds to your invesment account at various intervals, until the value reaches the target amount required to start investing. Once the target is reached, we'll notify you to visit the explore page and select available deals and continue with your investmnet journey.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Managing your portfolio",
    text: "Returns are paid once a vehicle is hired and value is captured during final handover. The booking history can be viewed from the portal as it contains a breakdown of fees and total generated for each booking.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Continuity",
    text: "After every  2 - 5 yrs or once an investment achieves 200%+ in profits, the vehicle will be replaced to maximize returns year-on-year, as well as ensure there's minimal depreciation towards a given investment. This means you'll continue to benefit from consitent returns across.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Opting - out / Termination",
    text: "If you'd like to opt out to an invesment. You will be required to advertise all assets in your portfolio on the selling page. The account will remain active, until another investor picks up the asset or the vehicle is sold completely. Read more",
    src: "./icon/placeholder.svg",
  },
];
const accountBenefits = [
  {
    title: "Powerful Dashboard",
    text: "Always be in the know of your performence with available 360 data at all times.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Payments & transfers",
    text: "Send and receive money between different accounts using multiple payment options.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Technology driven insights",
    text: "Advice based on your investment account trends, ensuring you always make the best decisions.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Fleet management",
    text: "Get detailed insights of your car's location and other superior details e.g Status (Moving).",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Booking history",
    text: "Keep track of payments each time the car is hired. View pay breakdown and share report.",
    src: "./icon/placeholder.svg",
  },
  {
    title: "Detailed reporting",
    text: "Get paid each time you car is hired, track performance and grow your portfolio .",
    src: "./icon/placeholder.svg",
  },
];
function HowItWorks() {
  return (
    <div>
      <AppMenu />
      <div className="HowItWorksContainer">
        <span className="LandingPageHeader">Let your money work for you</span>
        <span style={{ width: "620px" }} className="LandingPageSubHeader">
          Here’s how we can help you buy and invest in the right car, with the
          best deals and for the right price.
        </span>
      </div>
      <div data-aos="fade">
        <MoneyWork heading="How we make it happen" />
      </div>
      <div className="InvestmentProcess LandingPagePadding">
        <span className="LandingPageHeader">Investment Process</span>
        <span style={{ width: "620px" }} className="LandingPageSubHeader">
          Here's a detailed view of how the investment process works for, those
          who'd like to invest and own their car with Java Self Drive
        </span>
        {process.map((item, index) => (
          <div key={index} style={{ display: "flex", width: "770px" }}>
           <img style={{height:'70px'}} src={item.src} alt="icon" />
            <div style={{ marginLeft: "10px", marginBottom: "50px" }}>
              <span className="BenefitsListHeading">{item.title}</span>
              <p className="BenefitsListText">{item.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="InvestmentAccount LandingPagePadding">
        <span className="LandingPageHeader">Investment account</span>
        <span style={{ width: "620px" }} className="LandingPageSubHeader">
          Once you create an account with Java Self Drive, you'll be assigned an
          investment account with has the following features.
        </span>
        <Grid container spacing={2}>
        {accountBenefits.map((acc,index)=>(
            <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                <div>
                <img style={{height:'70px'}} src={acc.src} alt="icon" />
             <p className="BenefitsListHeading">{acc.title}</p>
             <p className="BenefitsListText">{acc.text}</p>
                </div>
                </Grid>

))}
            </Grid>
        
      </div>
    <div className="Boss">
     <BeBoss/>
    </div>
    <LandingPageFooter />
    </div>
  );
}

export default HowItWorks;
