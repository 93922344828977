import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Hidden,
  Drawer,
  Button
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FilterModal from "./ShowroomFilters/FilterModal";
import { BASE_URL, MAKE, MODEL } from "../../Services/Endpoints";
import { getRequest } from "../../Services/FetchFunctions";
import moment from "moment";
//import { thousandGroupSpacing } from "react-currency-format/lib/utils";

const FilterButtonApply = withStyles({
  root: {
    height: "45px",
    textTransform: "none",
    fontFamily: "inherit",
    fontSize: "14px",
    padding: "5px 30px",
    backgroundColor: "#d2dbff",
    border: "1px solid  #d2dbff",
    borderRadius: "5px",
    color: "#002ad1 !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#d2dbff",
      border: "1px solid  #d2dbff",
      transition: "0.2s ease-in-out"
    },
    "&:active": {
      backgroundColor: "#d2dbff",
      borderColor: "#d2dbff"
    }
  }
})(Button);

const stylesIndex = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  disabledButton: {
    color: "rgba(37, 43, 51, 0.5)" || "#000000"
  }
});

const themeShowroomFilter = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#FFFFFF"
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: " 0px 1px 0px #E5E9F2"
      }
    },
    MuiButton: {
      root: {
        marginRight: "15px",
        fontFamily: "inherit",
        color: "#000000",
        fontSize: "16px",
        fontWeight: 500,
        width: "100%",
        textTransform: "none",
        backgroundColor: "none !important",
        border: "2px solid #ffffff !important",
        position: "relative",
        "&:hover": {
          backgroundColor: "none !important"
        },
        "&:focus": {
          backgroundColor: "none !important"
        }
      }
    },
    MuiButtonBase: {
      root: {
        justifyContent: "space-between"
      }
    },
    MuiDrawer: {
      paper: {
        marginTop: "24px",
        width: "150px",
        alignItems: "normal",
        position: "inherit"
      },
      paperAnchorDockedLeft: {
        borderRight: "none",
        marginRight: "50px"
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5)",
        backdropFilter: "blur(2px)"
      }
    },
    MuiSelect: {
      root: {
        fontFamily: "inherit"
      },
      select: {
        color: "rgba(37, 37, 37, 0.7)",
        "&:focus": {
          backgroundColor: "none"
        }
      },
      icon: {
        position: "inherit"
      },
      nativeInput: {
        width: "auto",
        display: "none !important"
      }
    },
    MuiMenuItem: {
      root: {
        color: "rgba(37, 37, 37, 0.7)",
        fontFamily: "inherit",
        fontSize: "14px",
        height: "40px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f4f7ff !important"
        },
        "&$selected": {
          backgroundColor: "#f4f7ff"
        }
      }
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px"
      }
    },
    MuiInput: {
      root: {
        position: "inherit",
        height: "40px"
      },
      underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "inherit",
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
        height: "40px",
        border: "1px solid rgba(37, 43, 51, 0.15)",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)"
        },
        "&:focus": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)"
        }
      },
      input: {
        padding: "0px",
        border: "none !important",
        boxShadow: "none !important",
        margin: "0px !important"
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: "0 3px 6px rgba(0,0,0,.12)!important"
      }
    },
    MuiTypography: {
      body1: {
        fontFamily: "inherit"
      },
      body2: {
        fontFamily: "inherit"
      },
      caption: {
        fontFamily: "inherit"
      }
    },
    MuiPickersYear: {
      root: {
        fontFamily: "inherit"
      },
      yearSelected: {
        color: "rgba(37, 37, 37, 0.7)"
      }
    }
  }
});

class ShowroomFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [
        "Make",
        "Model",
        "Body type",
        "Year",
        "Price",
        "Mileage",
        "Gearbox",
        "Engine size",
        "Keyword"
      ],
      make: [],
      model: [],
      bodyType: [
        { type: "All cars", state: false },
        { type: "Saloons", state: false },
        { type: "Hatchbacks", state: false },
        { type: "Station wagons", state: false },
        { type: "4*4 SUV", state: false },
        { type: "Pickups", state: false },
        { type: "Mini, Coupes & Convertibles", state: false },
        { type: "Truck & Trailers", state: false },
        { type: "Tractors & Machinery", state: false },
        { type: "Vans and buses", state: false }
      ],
      menu: "",
      startDate: new Date(),
      endDate: new Date(),
      minPrice: 0,
      maxPrice: 0,
      mileage: 0,
      gearbox: "",
      engineSize: "",
      location: "",
      setOpen: false,
      searchModel: "",
      searchMake: "",
      selectedMake: {},
      selectedModel: {},
      selectedBodyType: [],
      editedDate: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelectedMake = this.handleSelectedMake.bind(this);
    this.handleSelectedModel = this.handleSelectedModel.bind(this);
    this.handleSelectedFilter = this.handleSelectedFilter.bind(this);
  }

  componentDidMount() {
    this.handleMakeFetch();
  }

  handleOpen() {
    this.setState({ setOpen: !this.state.setOpen });
  }

  handleMakeFetch() {
    getRequest(BASE_URL + MAKE)
      .then(response => {
        this.setState({ make: response.data });
      })
      .catch(err => console.log(err));
  }

  handleModelFetch(id) {
    getRequest(BASE_URL + MODEL + id)
      .then(response => {
        this.setState({ model: response.data });
      })
      .catch(err => console.log(err));
  }

  handleChecked(index) {
    let bodyType = [...this.state.bodyType];
    bodyType[index] = { ...bodyType[index], state: !bodyType[index].state };
    var filtered = this.handleBodyType(bodyType);
    this.setState({ bodyType, selectedBodyType: filtered });
    this.handleFilters(
      this.state.selectedMake.id,
      this.state.selectedModel.id,
      filtered
    );
  }

  handleStartDateChange = date => {
    this.setState({ startDate: date, editedDate: true });
  };
  handleEndDateChange = date => {
    this.setState({ endDate: date, editedDate: true });
  };

  handleSearch(searchId, searchItem) {
    this.setState({ [searchId]: searchItem });
  }

  handleSelectedMake(index) {
    this.setState({ selectedMake: this.state.make[index], selectedModel: "" });
    this.handleModelFetch(this.state.make[index].id);
    this.handleFilters(
      this.state.make[index].id,
      this.state.selectedModel.id,
      this.state.selectedBodyType
    );
  }

  handleSelectedModel(index) {
    this.setState({ selectedModel: this.state.model[index] });
    this.handleFilters(
      this.state.selectedMake.id,
      this.state.model[index].id,
      this.state.selectedBodyType
    );
  }

  handleSelectedFilter(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleMenu(menu) {
    var selectedMenu = this.state.menu;
    switch (menu) {
      case "Make":
        if (selectedMenu === "Make") {
          this.setState({ menu: "", setOpen: false });
        } else {
          this.handleOpen(menu);
          this.setState({ menu: menu, setOpen: true });
        }
        break;
      case "Model":
        if (selectedMenu === "Model") {
          this.setState({ menu: "", setOpen: false });
        } else {
          this.setState({ menu: menu, setOpen: true });
        }
        break;

      case "Body type":
        if (selectedMenu === "Body type") {
          this.setState({ menu: "", setOpen: false });
        } else {
          this.handleOpen(menu);
          this.setState({ menu: menu, setOpen: true });
        }
        break;
      default:
        if (selectedMenu === menu) {
          this.setState({ menu: "", setOpen: false });
        } else {
          this.setState({ menu: menu, setOpen: false });
        }
        break;
    }
  }

  handleChange(menuItem) {
    this.setState({ menu: menuItem });
    this.props.history.push(`/${menuItem}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  handleBodyType(bodyType) {
    var filtered = [];
    for (var i = 0; i < bodyType.length; i++) {
      if (bodyType[i].state === true) {
        filtered.push(bodyType[i].type);
      }
    }
    return filtered;
  }

  handleFilters(carMake, carModel, type) {
    var make = carMake !== undefined ? `&make=${carMake}` : "";
    var model = carModel !== undefined ? `&model=${carModel}` : "";
    var bodyType = type.length !== 0 ? `&bodyType=${type}` : "";
    var minYear =
      this.state.startDate !== "" && this.state.editedDate
        ? `&minYear=${moment(this.state.startDate).format("yyyy")}`
        : "";
    var maxYear =
      this.state.endDate !== "" && this.state.editedDate
        ? `&maxYear=${moment(this.state.endDate).format("yyyy")}`
        : "";
    var minPrice =
      this.state.minPrice !== 0 ? `&minPrice=${this.state.minPrice}` : "";
    var maxPrice =
      this.state.maxPrice !== 0 ? `&maxPrice=${this.state.maxPrice}` : "";
    var mileage =
      this.state.mileage !== 0 ? `&mileage=${this.state.mileage}` : "";
    var gearbox =
      this.state.gearbox !== "" ? `&gearbox=${this.state.gearbox}` : "";
    var engineSize =
      this.state.gearbox !== "" ? `&engineSize=${this.state.engineSize}` : "";
    var location =
      this.state.gearbox !== "" ? `&location=${this.state.location}` : "";

    var filters =
      make +
      model +
      bodyType +
      minYear +
      maxYear +
      minPrice +
      maxPrice +
      mileage +
      gearbox +
      engineSize +
      location;

    this.props.handleShowroomFetch(filters);
  }

  render() {
    const { classes } = this.props;
    var make = this.state.make.filter(entry =>
      Object.values(entry).some(
        val =>
          typeof val === "string" &&
          val.toLowerCase().includes(this.state.searchMake.toLowerCase())
      )
    );

    var model = this.state.model.filter(entry =>
      Object.values(entry).some(
        val =>
          typeof val === "string" &&
          val.toLowerCase().includes(this.state.searchModel.toLowerCase())
      )
    );

    return (
      <div
        className={classes.root}
        style={
          !this.props.mobileOpen && !this.props.open
            ? { display: "none" }
            : null
        }
      >
        <MuiThemeProvider theme={themeShowroomFilter}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor="left"
              open={this.props.mobileOpen}
              onClick={() => this.props.handleDrawerToggle("mobile")}
              style={!this.props.mobileOpen ? { display: "none" } : null}
              ModalProps={{
                keepMounted: true
              }}
            >
              <span className="LandingPageBlack Bold">Configure search</span>
              {this.state.filters.map((menu, index) => (
                <div key={index}>
                  <Button color="inherit" key={index}>
                    {menu}
                  </Button>
                </div>
              ))}
            </Drawer>
          </Hidden>
          <Hidden xsDown>
            <div className="DisplayFlex1">
              <Drawer
                variant="persistent"
                anchor="left"
                open={this.props.open}
                ModalProps={{
                  keepMounted: true
                }}
              >
                <span className="LandingPageBlack Bold">Configure search</span>
                {this.state.filters.map((menu, index) => (
                  <div key={index}>
                    <div className="DisplayFlex1">
                      <Button
                        color="inherit"
                        classes={{ disabled: classes.disabledButton }}
                        key={index}
                        onClick={() => this.handleMenu(menu)}
                        disabled={
                          Object.keys(this.state.selectedMake).length === 0 &&
                          (menu === "Model" || menu === "Body type")
                            ? true
                            : false
                        }
                      >
                        {menu}
                      </Button>
                      {this.state.menu === menu &&
                      this.state.menu !== "Make" &&
                      this.state.menu !== "Model" &&
                      this.state.menu !== "Body type" ? (
                        <img src="/icon/down1.svg" alt=">" />
                      ) : (
                        <img src="/icon/left.svg" alt=">" />
                      )}
                    </div>
                    {this.state.menu === "Year" && menu === "Year" ? (
                      <div className="FilterBody" style={{ padding: "10px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <label>From</label>
                          <DatePicker
                            style={{ width: "100%" }}
                            disableToolbar
                            disableFuture={true}
                            maxDate={this.state.startDate}
                            openTo="year"
                            views={["year"]}
                            variant="inline"
                            format="yyyy"
                            id="startDate"
                            InputProps={{
                              disableUnderline: true
                            }}
                            value={this.state.startDate}
                            onChange={this.handleStartDateChange}
                          />
                          <label>To</label>
                          <DatePicker
                            style={{ width: "100%" }}
                            disableToolbar
                            disableFuture={true}
                            InputProps={{
                              disableUnderline: true
                            }}
                            minDate={this.state.startDate}
                            openTo="year"
                            variant="inline"
                            format="yyyy"
                            id="endDate"
                            views={["year"]}
                            value={this.state.endDate}
                            onChange={this.handleEndDateChange}
                          />
                        </MuiPickersUtilsProvider>
                        <FilterButtonApply
                          onClick={() => {
                            this.handleFilters(
                              this.state.selectedMake.id,
                              this.state.selectedModel.id
                            );
                          }}
                        >
                          <span style={{ width: "inherit" }}>Set</span>
                        </FilterButtonApply>
                      </div>
                    ) : null}
                    {this.state.menu === "Price" && menu === "Price" ? (
                      <div className="FilterBody" style={{ padding: "10px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <label>From</label>
                          <input
                            id="minPrice"
                            type="number"
                            placeholder="Min"
                            value={this.state.minPrice}
                            onChange={this.handleSelectedFilter}
                            autoComplete="off"
                          />
                          <label>To</label>
                          <input
                            id="maxPrice"
                            type="number"
                            placeholder="Max"
                            value={this.state.maxPrice}
                            onChange={this.handleSelectedFilter}
                            autoComplete="off"
                          />
                        </MuiPickersUtilsProvider>
                        <FilterButtonApply
                          onClick={() => {
                            this.handleFilters(
                              this.state.selectedMake.id,
                              this.state.selectedModel.id
                            );
                          }}
                        >
                          <span style={{ width: "inherit" }}>Set</span>
                        </FilterButtonApply>
                      </div>
                    ) : null}
                  </div>
                ))}
              </Drawer>
              {this.state.setOpen ? (
                <FilterModal
                  handleOpen={this.handleOpen}
                  handleMenu={this.handleMenu}
                  handleChecked={this.handleChecked}
                  handleSelectedMake={this.handleSelectedMake}
                  handleSelectedModel={this.handleSelectedModel}
                  handleSearch={this.handleSearch}
                  setOpen={this.state.setOpen}
                  menu={this.state.menu}
                  bodyType={this.state.bodyType}
                  searchMake={this.state.searchMake}
                  searchModel={this.state.searchModel}
                  selectedMake={this.state.selectedMake}
                  selectedModel={this.state.selectedModel}
                  make={make}
                  model={model}
                />
              ) : null}
            </div>
          </Hidden>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(stylesIndex)(ShowroomFilter);
