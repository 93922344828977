import React from 'react';
import { Grid, Paper } from "@material-ui/core";


function MoneyWork(props) {
    return (
        <div
          id="HowItWorks"
          className="HowItWorks LandingPagePadding"
        >
          <span className="LandingPageHeader1">
           {props.heading}{" "}
          </span>
          <span className="subHeading">
            Invest in the right car, for the best deals. Save{" "}
            <span className="bolderBlue">time</span>, get{" "}
            <span className="bolderBlue">high returns</span> and grow your{" "}
            <span className="bolderBlue"> wealth</span>
          </span>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "60px", marginBottom: "60px" }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper
                elevation={0}
                className="PaperWork"
                style={{ background: "#fcd9d9" }}
              >
                <img src="./icon/rocket.svg" alt="icon" />
                <div>
                  <span style={{ fontSize: "24px" }} className="BlackText">
                    Get started in minutes
                  </span>
                </div>
                <p style={{ fontSize: "16px" }} className="BlackText">
                  Open your investment account online. Everything done hassle
                  free, complete setting up your investment profile and start
                  investing with Java Self Drive.
                </p>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper
                elevation={0}
                className="PaperWork"
                style={{ background: "#cbf6e4" }}
              >
                <div>
                <img src="./icon/monitoring.svg" alt="icon" />
                </div>
                <div>
                  <span style={{ fontSize: "24px" }} className="BlackText">
                    Manage your portfolio
                  </span>
                </div>
                <p style={{ fontSize: "16px" }} className="BlackText">
                  Invest in low mileage vehicles available on our deals page.
                  Make payments from your invetment wallet and get paid each
                  time the car is hired.
                </p>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper
                elevation={0}
                className="PaperWork"
                style={{ background: "#fde8d8" }}
              >
                <div>
                <img src="./icon/investing.svg" alt="icon" />
                </div>
                 <div>
                  <span style={{ fontSize: "24px" }} className="BlackText">
                  Grow your wealth
                  </span>
                </div>
                <p style={{ fontSize: "16px" }} className="BlackText">
                Our technology powered insights, will advice you on the best actions to take based on account trends & your portfolio performance.
                </p>
              </Paper>
            </Grid>
          </Grid>
        </div>
    )
}

export default MoneyWork;
