import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import BodyType from "./BodyType";
import MakeFilter from "./MakeFilter";
import ModelFilter from "./ModelFilter";

const stylesModal = theme => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "160px",
    marginTop: "20%"
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0 9px 50px 22px rgba(0, 0, 0, 0.05)"
  },
  container: {
    minHeight: 450,
    overflowX: "hidden"
  }
});

class FilterModal extends Component {
  handleView(view) {
    if (view === "Make") {
      return (
        <MakeFilter
          handleSearch={this.props.handleSearch}
          handleSelectedMake={this.props.handleSelectedMake}
          selectedMake={this.props.selectedMake}
          searchMake={this.props.searchMake}
          make={this.props.make}
        />
      );
    } else if (view === "Model") {
      return (
        <ModelFilter
          handleSearch={this.props.handleSearch}
          handleSelectedModel={this.props.handleSelectedModel}
          selectedModel={this.props.selectedModel}
          searchModel={this.props.searchModel}
          model={this.props.model}
        />
      );
    } else if (view === "Body type") {
      return (
        <BodyType
          bodyType={this.props.bodyType}
          handleChecked={this.props.handleChecked}
          checked={this.props.checked}
        />
      );
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="ShowroomModal">
        <div className={classes.paper}>
          <div className="ModalPaper" style={{ padding: "22px" }}>
            {this.handleView(this.props.menu)}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(stylesModal)(FilterModal);
