import React, { Component } from "react";
import {
  createMuiTheme,
  Select,
  MuiThemeProvider,
  MenuItem,
} from "@material-ui/core";
import { numberCheck } from "../../Utilities/SharedFunctions";
import { CircularProgress } from "@material-ui/core";
import Successful from "./Successful";
import { post_request,getRequest } from "../../Services/FetchFunctions";

const MenuProps = {
  style: {
    marginTop: "15px",
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const customTopUp = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontFamily: "inherit",
      },
      select: {
        color: "rgba(37, 37, 37, 0.7)",
        "&:focus": {
          backgroundColor: "none",
        },
      },
      icon: {
        position: "absolute",
      },
      nativeInput: {
        width: "auto",
        display: "none !important",
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgba(37, 37, 37, 0.7)",
        fontFamily: "inherit",
        fontSize: "14px",
        height: "40px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f4f7ff !important",
        },
        "&$selected": {
          backgroundColor: "#f4f7ff",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    MuiInput: {
      root: {
        position: "inherit",
        height: "40px",
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "inherit",
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
        height: "40px",
        border: "1px solid rgba(37, 43, 51, 0.15)",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
        "&:focus": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
      },
      input: {
        padding: "0px",
        border: "none !important",
        boxShadow: "none !important",
        margin: "0px !important",
      },
    },
  },
});

class CashOutPaypal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      amount: "",
      email: "",
      selectedCurrency: "USD",
      availableCurrencies: ["KES", "USD", "GBP", "EUR"],
      isLoading: false,
      investmentAccount:[],
      verificationCode: ["", "", "", "", ""],
      transMessage: "Thanks for paying",
      paymentRef: "ABCD123",
      totalAmount: "0.00",
      charges: "1.00",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      transDescription:'One two three',
      validationLoading: false
    };

    this.handleFormInputNumber = this.handleFormInputNumber.bind(this);
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleVerificationInput = this.handleVerificationInput.bind(this);
  }

  handleView(view) {
    this.setState({ view: view });
  }
  componentDidMount(){
    this.getAccounts();
  }

  handleFormInputNumber(event) {
    if (event.target.value.length > 0) {
      if (numberCheck(event.target.value)) {
        this.setState({ [event.target.id]: event.target.value });
      }
    } else {
      this.setState({ [event.target.id]: "" });
    }
  }

  handleFormInput(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }
  getAccounts(){
    const url = "client/account/get-accounts";
    getRequest(url)
      .then((resp) => {
        var accounts = resp.data.List;
        var filteredAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        this.setState({
          investmentAccount: filteredAccount
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
}

handleVerificationInput(event) {
  var index = event.target.id;
  const newArray = Array.from(this.state.verificationCode);
  const nextSibling = document.querySelector(
    `input[name=ssn-${parseFloat(index, 10) + 1}]`
  );

  if (numberCheck(event.target.value)) {
    if (event.target.value.length > 1) {
      for (var i = 0; i < 5; i++) {
        newArray[i] = event.target.value.charAt(i);
      }
      document.querySelector(`input[name=ssn-4]`).focus();
    } else {
      newArray[index] = event.target.value;
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
    this.setState({ verificationCode: newArray });
  } else {
    newArray[index] = "";
    this.setState({ verificationCode: newArray });
  }
}
handleValidation() {
  this.setState({validationLoading:true})
  var url = "client/account/cash-out/validate";
  var verificationCode = this.state.verificationCode
    .toString()
    .replace(/,/g, "");
  var payload = {
    code: verificationCode,
  };
  post_request(url, payload)
    .then((resp) => {
      this.setState({
        validationLoading: false,
        snackbaropen: true,
        snackbarmsg: "Transaction successful",
        responseStatus: "success",
        snackbartxt: resp.data.Message,
        view: 2
      });
      setTimeout(function () {
        window.location.reload();
      }, 1500);
    })
    .catch((err) => {
     // console.log(err.response);
      this.setState({
        validationLoading: false,
        snackbaropen: true,
        snackbarmsg: "Could not process payment",
        responseStatus: "failed",
        snackbartxt: err.response.data.Message,
      });
    });
}

handleSubmit() {
  this.setState({ isLoading: true, view: 1 });
  var url = "client/account/cash-out";
  var inv = this.props.inv[0];
  var payload = {
    Amount: this.state.amount,
    AccountNumber: inv.AccountNumber,
    Account: this.state.email,
    Type: "PAYPAL-CASHOUT",
    Description: this.state.transDescription,
  };
  //console.log(payload);
  post_request(url, payload)
    .then((resp) => {
      //console.log(resp);
      this.setState({
        isLoading: false,
        transMessage: resp.data.Message,
        paymentRef: resp.data.PaymentRef,
        totalAmount: resp.data.TotalAmount,
        charges: resp.data.Charges,
        view: 1,
      });
      //this.handleValidation();
    })
    .catch((err) => {
      this.setState({
        isLoading: false,
        snackbaropen: true,
        snackbarmsg: "Could not process payment",
        responseStatus: "failed",
        snackbartxt: err.response.data.Message,
      });
    });
}

  render() {
    var account = this.state.investmentAccount[0];
    if (account !== undefined) {
      var curr = account.AccountCurrency;
      var balance = account.AccountBalance;
    }
    let CurrencyFormat = require("react-currency-format");
    return (
      <div>
        <div className="ButtonPadding">
          <button
            className="BackButton"
            onClick={() => {
              this.state.view === 0
                ? this.props.handleView(1)
                : this.handleView(0);
            }}
          >
            <img src="/icon/back.svg" alt="back" />
            <span>Back</span>
          </button>
        </div>
        {this.state.view === 0 && (
          <div className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Cash-out to your Paypal account
                </span>
                <span className="MainContentSubheader">
                  Please enter the amount you’d like to send to your paypal
                  account{" "}
                </span>
              </div>
              <div className="SendMoneyBalance">
                <img
                  style={{ marginRight: "5px" }}
                  src="/icon/entypo_wallet.svg"
                  alt="wallet"
                />
                <span className="MainContentSubheader">Available balance</span>
                <span className="BlueBold">{curr} {balance}</span>
              </div>
              <MuiThemeProvider theme={customTopUp}>
                <div className="ProfileForm">
                  <label style={{ display: "block" }}>
                    How much do you want to cash out?
                  </label>

                  <label style={{ marginTop: "10px" }}>Amount</label>
                  <div className="SignUpFormsTwoInOne">
                    <div className="SignUpFormsTwoInOneSmall">
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                        }}
                        name="selectedCurrency"
                        value={this.state.selectedCurrency}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        {this.state.availableCurrencies.map((code, index) => (
                          <MenuItem value={code} key={index}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <input
                      id="amount"
                      type="text"
                      autoComplete="off"
                      maxLength="9"
                      value={this.state.amount}
                      onChange={this.handleFormInputNumber}
                      required
                    />
                  </div>
                  <label>Enter your Paypal email address</label>
                  <input
                    id="email"
                    type="text"
                    maxLength="50"
                    value={this.state.email}
                    onChange={this.handleFormInput}
                    required
                  />
                  <label>Reason for transaction(Narration)</label>
                  <input
                    id="transDescription"
                    type="text"
                    value={this.state.transDescription}
                    onChange={this.handleFormInput}
                    required
                  />
                  <button
                    className={
                      this.state.amount !== "" &&
                      this.state.email !== ""
                        ? "SignUpFormsSubmit"
                        : "SignUpFormsSubmitDisabled"
                    }
                    disabled={
                      this.state.amount !== "" &&
                      this.state.email !== ""
                        ? false
                        : true
                    }
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Continue with Paypal"
                    )}
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        )}
        {this.state.view === 1 && (
           <div className="ContentSpace">
           <div className="PersonalInfo PaymentsForm">
             <div
               className="ContentHeader"
               style={{ textAlign: "center", marginBottom: "35px" }}
             >
               <span
                 className="MainContentHeader Bold"
                 style={{ display: "block", marginBottom: "10px" }}
               >
                 {this.state.transMessage}{" "}
               </span>
               <span className="MainContentSubheader">
                 Confirm the transaction details and enter the code sent to
                 your phone below
               </span>

               <div style={{ height: "235px" }} className="SendMoneyBalance">
                 <span className="MainContentSubheader">You are sending</span>

                 <span
                   // style={{ color: "#252b33", marginBottom: "20px" }}
                   className="BlueBold"
                 >
                   {curr}{" "}
                   <CurrencyFormat
                     value={parseFloat(this.state.totalAmount).toFixed(2)}
                     displayType={"text"}
                     thousandSeparator={true}
                   />
                 </span>
                 <br />
                 <div style={{ marginBottom: "20px" }}>
                     <span className="MainContentSubheader">
                       From your <b>Main Account</b>
                     </span>
                     <span
                       style={{ display: "block" }}
                       className="MainContentSubheader"
                     >
                       To your <b>Paypal account </b>
                     </span>
                   </div>

                 {/* <span className="BlueBold">KES {this.state.totalAmount}</span> */}
                 <span className="MainContentSubheader">
                   Transaction fee: {this.state.charges}
                 </span>
                 <span
                   style={{ display: "block" }}
                   className="MainContentSubheader"
                 >
                   Transaction Reference Number: {this.state.paymentRef}
                 </span>
               </div>
               <div className="SignFormDetails">
                 <div
                   className="DisplayFlexSpace"
                   style={{ marginBottom: "20px" }}
                 >
                   <div className="Verification">
                     <input
                       name="ssn-0"
                       id="0"
                       type="text"
                       autoComplete="off"
                       value={this.state.verificationCode[0]}
                       onChange={this.handleVerificationInput}
                       required
                     />
                   </div>
                   <div className="Verification">
                     <input
                       name="ssn-1"
                       id="1"
                       type="text"
                       maxLength="1"
                       autoComplete="off"
                       value={this.state.verificationCode[1]}
                       onChange={this.handleVerificationInput}
                       required
                     />
                   </div>
                   <div className="Verification">
                     <input
                       name="ssn-2"
                       id="2"
                       type="text"
                       maxLength="1"
                       autoComplete="off"
                       value={this.state.verificationCode[2]}
                       onChange={this.handleVerificationInput}
                       required
                     />
                   </div>
                   <div className="Verification">
                     <input
                       name="ssn-3"
                       id="3"
                       type="text"
                       maxLength="1"
                       autoComplete="off"
                       value={this.state.verificationCode[3]}
                       onChange={this.handleVerificationInput}
                       required
                     />
                   </div>
                   <div className="Verification">
                     <input
                       name="ssn-4"
                       id="4"
                       type="text"
                       maxLength="1"
                       autoComplete="off"
                       value={this.state.verificationCode[4]}
                       onChange={this.handleVerificationInput}
                       onKeyPress={(e) => {
                         this.handleKeyPress(e);
                       }}
                       required
                     />
                   </div>
                 </div>
               </div>
               <button
                 className="SignUpFormsSubmit"
                 onClick={() => this.handleValidation()}
                 disabled={this.state.validationLoading}
               >
                 {this.state.validationLoading ? (
                   <CircularProgress style={{ color: "white" }} size={20} />
                 ) : (
                   "Cash Out"
                 )}
               </button>
             </div>
           </div>
         </div>
        )}
        { this.state.view === 2 && (
          <div className="ContentSpace">
            
            <Successful />
          
          </div>
        )}
      </div>
    );
  }
}
export default CashOutPaypal;
