import React, { Component } from "react";
import Loader from "../../Components/SharedComponents/Loader";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import { Grid, withStyles, Paper } from "@material-ui/core";
import AccountBalances from "../../Components/Dashboard/AccountBalances";
import QuickActions from "../../Components/Dashboard/QuickActions";
import BookingActivity from "../../Components/Dashboard/BookingActivity";
import RecentBookings from "../../Components/Dashboard/RecentBooking";
import TopCar from "../../Components/Dashboard/TopCar";
import LearnMore from "../../Components/Dashboard/LearnMore";
import BookingRevenue from "../../Components/Dashboard/BookingRevenue";
import Tour from "../../Components/Tour";
import {getRequest} from "../../Services/FetchFunctions";

const styles = theme => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px #f1f1f1",
    backgroundColor: "rgba(255, 255, 255, 0.63)"
  },
  root: {
    maxWidth: 400,
    flexGrow: 1
  }
});

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      completed: 4,
      buffer: 10,
      title: "",
      message: "",
      accounts: [],
      dets:[],
      bookingStatus: 0,
      revenue: [0, 0, 0, 0, 0, 0, 0],
      bookings: [],
      topCar: [],
      otherAccounts:[
        {
            AccountCurrency: "KES",
            AccountBalance: "0.0",
            AccountType: "MAIN",
            AccountNumber: "1624568967941325",
            AccountName: "Default User"
        },
        {
          AccountCurrency: "KES",
          AccountBalance: "0.0",
          AccountType: "INVESTMENT",
          AccountNumber: "1624568967941325",
          AccountName: "Default User"
        }
    ],
      validEmail: false,
      success: false,
      open: false,
      isLoading: false,
      blocking: false,
      selectedPeriod: "W",
      activeTour: 0,
      tourOpen: false,
      mainAcc:[],
      investAcc: [],
      incomeAcc:[],
      username: ""
    };
    this.handleSelectedPeriod = this.handleSelectedPeriod.bind(this);
    this.handleDashboardTour = this.handleDashboardTour.bind(this);
    
  }
  componentDidMount() {
  
    this.getTopCar();
    this.getDets();
  }
  getDets() {
    var endpoint = "client/get-details";
    getRequest(endpoint)
      .then((resp) => {
        let accounts = resp.data.accountDetails;
        let mainAccount = accounts.filter(
          (account) => account.AccountType === "MAIN"
        );
        let investAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        let incomeAccount = accounts.filter(
          (account) => account.AccountType === "INCOME"
        );
        var nameUser = resp.data.firstName + " " + resp.data.lastName;
        this.setState({
          dets: resp.data,
          accounts: resp.data.accountDetails,
          mainAcc: mainAccount,
          investAcc: investAccount,
          username: nameUser,
          incomeAcc: incomeAccount
        });
        
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getTopCar() {
    const url = "client/vehicle/list";
    getRequest(url)
      .then((resp) => {
        this.setState({
          topCar: resp.data.List,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
 
  handleSelectedPeriod(period) {
    this.setState({ selectedPeriod: period });
  }

  handleDashboardTour(active, open) {
    this.setState({ activeTour: active, tourOpen: open });
  }
  

  render() {
    const { classes } = this.props;
    var account = this.state.mainAcc[0];
    let incomeAcc = this.state.incomeAcc[0];
    let incomeAccountBalance = '0.00';
        var accountInv = this.state.investAcc[0];
        var investment, mainAccount;
        if (accountInv !== undefined  ) {
          investment = accountInv;
          mainAccount = account.AccountBalance;
           if(account !== undefined){
            mainAccount = account.AccountBalance;
            
           }
           if(incomeAcc !== undefined){
            incomeAccountBalance = incomeAcc.AccountBalance 
           }
          var username = this.state.username;
        } else {
          investment = this.state.otherAccounts
        }

    return (
      <div>
        {this.state.isLoading ? (
          <Loader text="Preparing your dashboard" />
        ) : (
          <div className="ContainerApp">
            <TopBar
              handleDashboardTour={this.handleDashboardTour}
            />
            <div className="MainBody">
              <div className="Menu">
                {this.state.activeTour === 2 && this.state.tourOpen ? (
                  <Tour
                    handleDashboardTour={this.handleDashboardTour}
                    activeTour={this.state.activeTour}
                  />
                ) : null}

                <Menu />
              </div>
              <div className="Content">
                <div className="ContentHeader">
                  <span className="MainContentHeader Bold">My dashboard</span>
                  <br />
                  <span className="MainContentSubheader">
                    Here you can view your wallet and booking information as
                    well as overall performance
                  </span>
                </div>
                <div className="ContentSpace">
                  <Grid container spacing={2}>
                    <Grid className="accountBalance" item xs={12} sm={12} md={12} lg={6}>
                      {this.state.activeTour === 0 && this.state.tourOpen ? (
                        <Tour
                          handleDashboardTour={this.handleDashboardTour}
                          activeTour={this.state.activeTour}
                        />
                      ) : null}
                      <Paper
                        className={classes.paper}
                        style={{ height: "179px" }}
                      >
                       <AccountBalances accountInv={investment} accountMain={mainAccount} income={incomeAccountBalance} name={username} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <Paper
                        className={classes.paper}
                        style={{ height: "179px" }}
                      >
                        <QuickActions  />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      {this.state.activeTour === 1 && this.state.tourOpen ? (
                        <Tour
                          handleDashboardTour={this.handleDashboardTour}
                          activeTour={this.state.activeTour}
                        />
                      ) : null}
                      <Paper
                        className={classes.paper}
                        style={{ height: "179px" }}
                      >
                        <BookingActivity status={this.state.bookingStatus} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="MiddleCards"
                      >
                        {this.state.activeTour === 3 && this.state.tourOpen ? (
                          <Tour
                            handleDashboardTour={this.handleDashboardTour}
                            activeTour={this.state.activeTour}
                          />
                        ) : null}

                        <Paper
                          className={classes.paper}
                          style={{ height: "290px" }}
                        >
                          <BookingRevenue
                            handleSelectedPeriod={this.handleSelectedPeriod}
                            selectedPeriod={this.state.selectedPeriod}
                            revenue={this.state.revenue}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper
                          className={classes.paper}
                          style={{ minHeight: "259px" }}
                        >
                          <TopCar topCar={this.state.topCar} />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="MiddleCards"
                      >
                        <Paper
                          className={classes.paper}
                          style={{ height: "290px" }}
                        >
                          <RecentBookings bookings={this.state.bookings} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper
                          className={classes.paper}
                          style={{ height: "259px" }}
                        >
                          <LearnMore />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
