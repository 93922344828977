import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { post_request } from "../../Services/FetchFunctions";
import {
  withStyles,
  createMuiTheme,
  Modal,
  Backdrop,
  MuiThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import {
  passwordStrength,
  charCheck,
  alphaCheck,
  numberCheck,
} from "../../Utilities/SharedFunctions";
import CustomSnackbar from "../../Components/SharedComponents/CustomSnackbar";

const stylesModal = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "inherit",
  },
  container: {
    minHeight: 450,
    overflowX: "hidden",
  },
});

const themeModal = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5",
        backdropFilter: "blur(1px)",
      },
    },
  },
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsubscribe: false,
      setOpen: false,
      currentPassword: "",
      newPassword: "",
      showValidationHelper: false,
      validPassword: false,
      passwordConfirm: false,
      confirmPassword: "",
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
    };
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
  }

  handleOpen() {
    this.setState({ setOpen: !this.state.setOpen });
  }
  handleFormInput(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === "newPassword" && event.target.value.length > 0) {
      this.setState({
        showValidationHelper: true,
        confirmPassword: "",
        passwordConfirm: false,
      });
      var validationArray = [
        event.target.value.length < 8 ? false : true,
        alphaCheck(event.target.value),
        numberCheck(event.target.value),
        charCheck(event.target.value),
      ];
      var numOfTrue = validationArray.filter(function (x) {
        return x === true;
      }).length;

      if (passwordStrength(event.target.value)) {
        this.setState({
          validPassword: true,
          validCount: numOfTrue,
        });
      } else {
        this.setState({
          validPassword: false,
          showValidationHelper: true,
          validCount: numOfTrue,
        });
      }
    } else {
      this.setState({ validPassword: false, showValidationHelper: false });
    }
  }
  confirmPassword(event) {
    if (event.target.value !== "" && this.state.validPassword) {
      this.setState({ showValidationHelper: false });
      if (event.target.value === this.state.newPassword) {
        this.setState({
          passwordConfirm: true,
          confirmPassword: event.target.value,
        });
      } else {
        this.setState({
          passwordConfirm: false,
          confirmPassword: event.target.value,
        });
      }
    } else {
      this.setState({
        passwordConfirm: false,
        confirmPassword: event.target.value,
      });
    }
  }
  handleSubmit = async () => {
    this.setState({ isLoading: true });
    const url = "change-password";
    var payload = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
    };
    post_request(url, payload)
      .then((resp) => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Password sent successfully",
          responseStatus: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Password change failed",
          responseStatus: "failed",
        });
      });
  };
  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <MuiThemeProvider theme={themeModal}>
          <Modal
            className={classes.modal}
            open={this.props.setOpen}
            onClose={() => this.props.handleOpen()}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <div className={classes.paper}>
              <div className="ModalPaper">
                <div className="ModalHeader" style={{ padding: "0px" }}>
                  <div>
                    <button
                      className="BackButton"
                      style={{ float: "right" }}
                      onClick={() => this.props.handleOpen()}
                    >
                      <img src="./icon/close.svg" alt="x" />
                    </button>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "inline-block",
                      marginTop: "50px",
                    }}
                  >
                    <span
                      className="MainContentHeader"
                      style={{ fontSize: "20px" }}
                    >
                      Change password{" "}
                    </span>
                  </div>
                </div>
                <div className="ProfileForm" style={{ marginTop: "30px" }}>
                  <label>Current password</label>
                  <input
                    id="currentPassword"
                    type="password"
                    value={this.state.currentPassword}
                    onChange={this.handleFormInput}
                    className="SignUpFormsPassword"
                    required
                  />
                  <label>New password</label>
                  <div className="SignUpFormsPasswordIcon">
                    <input
                      id="newPassword"
                      type="password"
                      value={this.state.newPassword}
                      onChange={this.handleFormInput}
                      className="SignUpFormsPassword"
                      style={
                        this.state.showValidationHelper
                          ? { marginBottom: "10px" }
                          : null
                      }
                      required
                    />
                    {this.state.validPassword ? (
                      <img
                        className="SignUpFormsPasswordIconCheck"
                        alt="status"
                        src="/icon/check.svg"
                      />
                    ) : null}
                  </div>
                  {this.state.showValidationHelper ? (
                    <div className="SignUpFormsPasswordValidation">
                      <div className="DisplayFlexSpace">
                        <div
                          className="SignUpFormsPasswordValidationPill"
                          style={
                            this.state.validCount >= 1
                              ? this.state.validCount === 4
                                ? { background: "#2EB57E" }
                                : { background: "#EFC225" }
                              : null
                          }
                        ></div>
                        <div
                          className="SignUpFormsPasswordValidationPill"
                          style={
                            this.state.validCount >= 2
                              ? this.state.validCount === 4
                                ? { background: "#2EB57E" }
                                : { background: "#EFC225" }
                              : null
                          }
                        ></div>
                        <div
                          className="SignUpFormsPasswordValidationPill"
                          style={
                            this.state.validCount >= 3
                              ? this.state.validCount === 4
                                ? { background: "#2EB57E" }
                                : { background: "#EFC225" }
                              : null
                          }
                        ></div>
                        <div
                          className="SignUpFormsPasswordValidationPill"
                          style={
                            this.state.validCount === 4
                              ? { background: "#2EB57E" }
                              : null
                          }
                        ></div>
                      </div>
                      <div>
                        <span
                          style={
                            this.state.validCount === 4
                              ? { color: "#2EB57E" }
                              : { color: "#EFC225" }
                          }
                        >
                          {this.state.validCount === 4 ? "Strong" : "Weak"}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <label>Confirm password</label>
                  <div className="SignUpFormsPasswordIcon">
                    <input
                      id="confirmPassword"
                      type="password"
                      value={this.state.confirmPassword}
                      onChange={this.confirmPassword}
                      className="SignUpFormsPassword"
                      style={
                        !this.state.passwordConfirm &&
                        this.state.confirmPassword !== ""
                          ? { border: "2px solid #F05050" }
                          : null
                      }
                      required
                    />
                    {this.state.passwordConfirm ? (
                      <img
                        className="SignUpFormsPasswordIconCheck"
                        alt="status"
                        src="/icon/check.svg"
                      />
                    ) : null}
                  </div>
                  {!this.state.passwordConfirm &&
                  this.state.confirmPassword !== "" ? (
                    <div className="PasswordConfirm">
                      <span>! Passwords do not match</span>
                    </div>
                  ) : null}
                </div>

                <div className="ModalFooter">
                  <button
                    className={
                      !this.state.passwordConfirm ||
                      this.state.currentPassword === ""
                        ? "SignUpFormsSubmitDisabled"
                        : "SignUpFormsSubmit"
                    }
                    disabled={
                      !this.state.passwordConfirm ||
                      this.state.currentPassword === ""
                        ? true
                        : false
                    }
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Save new password"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      </div>
    );
  }
}
export default withRouter(withStyles(stylesModal)(ChangePassword));
