import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";

const useStyles = theme => ({
  button: {
    "&&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const themeBodyType = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiButton: {
      textSecondary: {
        color: "#003C58 !important"
      },
      textPrimary: {
        color: "#1A1A1A"
      }
    },
    MuiSvgIcon: {
      root: {
        position: "inherit",
        fill: "#000000",
        width: "0.8em",
        height: "0.8em"
      }
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "0"
      }
    },
    MuiTypography: {
      body1: {
        fontFamily: "inherit"
      }
    },
    MuiCheckbox: {
      root: {
        color: "#2EB57E !important",
        "&&:hover": {
          backgroundColor: "transparent"
        },
        "&$checked": {
          color: "#2EB57E !important",
          "&&:hover": {
            backgroundColor: "transparent"
          }
        }
      }
    }
  }
});

class BodyType extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={themeBodyType}>
          {this.props.bodyType.map((type, index) => (
            <div className="DisplayFlexSpace" key={index}>
              <div>
                <span className="MainContentHeader">{type.type}</span>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={type.state}
                    className={classes.button}
                    onChange={() => this.props.handleChecked(index)}
                    name={type.type}
                    color="primary"
                  />
                }
              />
            </div>
          ))}
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(useStyles)(BodyType);
