import React, { Component } from "react";
import {
  createMuiTheme,
  Select,
  MuiThemeProvider,
  MenuItem,
} from "@material-ui/core";
import { numberCheck } from "../../Utilities/SharedFunctions";
import { CircularProgress } from "@material-ui/core";
import Successful from "./Successful";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '../SharedComponents/Tooltip';
import AddBeneficiary from './AddBeneficiary';

const MenuProps = {
  style: {
    marginTop: "15px",
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const customTopUp = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontFamily: "inherit",
      },
      select: {
        color: "rgba(37, 37, 37, 0.7)",
        "&:focus": {
          backgroundColor: "none",
        },
      },
      icon: {
        position: "absolute",
      },
      nativeInput: {
        width: "auto",
        display: "none !important",
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgba(37, 37, 37, 0.7)",
        fontFamily: "inherit",
        fontSize: "14px",
        height: "40px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f4f7ff !important",
        },
        "&$selected": {
          backgroundColor: "#f4f7ff",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    MuiInput: {
      root: {
        position: "inherit",
        height: "40px",
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "inherit",
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
        height: "40px",
        border: "1px solid rgba(37, 43, 51, 0.15)",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
        "&:focus": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
      },
      input: {
        padding: "0px",
        border: "none !important",
        boxShadow: "none !important",
        margin: "0px !important",
      },
    },
  },
});


// const styles = theme => ({
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: "none",
//     borderRadius: "2px",
//     boxShadow: "-30px 4px 60px 30px rgba(37, 43, 51, 0.03)",
//     padding: "30px"
//   }
// });


class SendWireTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      amount: "",
      selectedCurrency: "KES",
      availableCurrencies: ["KES", "USD", "GBP", "EUR"],
      isLoading: false,
      setOpen: false,
      openModal:false
    };

    this.handleFormInputNumber = this.handleFormInputNumber.bind(this);
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
   this.showModal = this.showModal.bind(this);
  }

  handleView(view) {
    this.setState({ view: view });
  }

  handleFormInputNumber(event) {
    if (event.target.value.length > 0) {
      if (numberCheck(event.target.value)) {
        this.setState({ [event.target.id]: event.target.value });
      }
    } else {
      this.setState({ [event.target.id]: "" });
    }
  }

  handleFormInput(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit() {
    this.setState({ isLoading: true, view: 1 });
    // var payload = {
    //   amount: this.state.amount,
    //   selectedCurrency: this.state.selectedCurrency,
    // };
  }
  handleOpen() {
    this.setState({ setOpen: !this.state.setOpen });
  }
  showModal() {
    this.setState({ openModal: !this.state.openModal });
  }
  
  

  render() {
    //const { classes } = this.props;
    return (
      <div>
        <Tooltip handleOpen={this.handleOpen} setOpen={this.state.setOpen}  />
        {this.state.openModal ? (
          <AddBeneficiary
            showModal={this.showModal}
            openModal={this.state.openModal}
          />
        ) : null}
        <div className="ButtonPadding">
          <button
            className="BackButton"
            onClick={() => {
              this.state.view === 0
                ? this.props.handleView(0)
                : this.handleView(0);
            }}
          >
            <img src="/icon/back.svg" alt="back" />
            <span>Back</span>
          </button>
        </div>
        {this.state.view === 0 ? (
          <div className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Send money via wire transfer
                </span>
                <span className="MainContentSubheader">
                Please fill in the details below to initiate the wire transfer{" "}
                </span>
                <label style={{ display: "block", marginTop: "10px",textAlign:"left" }} className="Bold">From(Select Wallet)</label>
                <div className="SendMoneyBalance">
                  <div style={{ alignItems: "center", display: "flex"}} >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/icon/entypo_wallet.svg"
                    alt="wallet"
                  />
                  <span className="MainContentSubheader">
                    Available balance
                  </span>
                  <div onClick={() => this.handleOpen()}>
                  <HelpOutlineIcon  className="tip"/>
                  </div>
                  </div>
                  
                  <span className="BlueBold">KES 312,500.00</span>
                  <span className="MainContentSubheader">Actual balance </span>
                  <span className="Bold">KES 312,500.00</span>
                </div>
                <label style={{ display: "block", marginTop: "10px",textAlign:"left" }} className="Bold">To</label>
                <div className="SendMoneyBalance">
                    <div style={{ alignItems: "center"}}>
                  <img
                    style={{ marginRight: "5px" }}
                    src="/icon/building-bank.svg"
                    alt="bank"
                  />
                  <span className="MainContentSubheader">
                  Standard Chattered Bank
                  </span>
                  </div>
                  <span style={{ display: "block",margin:"5px 0px 20px 30px", }} className="Bold">John Hartley</span>
                  <div style={{ display: "flex",justifyContent:"space-between" }}>
                  <img
                    src="/icon/trash.svg"
                    alt="trash"
                    //onClick={() => this.setState({ profilePic: "" })}
                  />
                  <span className="MainContentSubheader">Wire-transfer bank account </span>
                  </div>
                  
                </div>
                
              </div>
              <MuiThemeProvider theme={customTopUp}>
              <span onClick={() => this.showModal()} className="RedirectButtonMain" > + Add new account</span>
                <div className="ProfileForm">
                  
                  <label style={{ marginTop: "10px" }}>Amount</label>
                  <div className="SignUpFormsTwoInOne">
                    <div className="SignUpFormsTwoInOneSmall">
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                        }}
                        name="selectedCurrency"
                        value={this.state.selectedCurrency}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        {this.state.availableCurrencies.map((code, index) => (
                          <MenuItem value={code} key={index}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <input
                      id="amount"
                      type="text"
                      autoComplete="off"
                      maxLength="9"
                      value={this.state.amount}
                      onChange={this.handleFormInputNumber}
                      required
                    />
                    
                  </div>
                  <div style={{ display: "flex",justifyContent:"space-between" }}>
                    <span className="MainContentSubheader">Transfer fee </span>
                  <span className="MainContentSubheader">KES 500.00 </span>
                  </div>
                  <div style={{ display: "flex",justifyContent:"space-between",margin: "10px 0px" }}>
                    <span className="MainContentSubheader">Debit amount </span>
                  <span  className="MainContentSubheader">KES 500.00 </span>
                  </div>
                  
                  <button
                    className={
                      this.state.amount !== "" &&
                      this.state.selectedCurrency !== ""
                        ? "SignUpFormsSubmit"
                        : "SignUpFormsSubmitDisabled"
                    }
                    disabled={
                      this.state.amount !== "" &&
                      this.state.selectedCurrency !== ""
                        ? false
                        : true
                    }
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Send money"
                    )}
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        ) : (
          <div className="ContentSpace">
            <Successful />
          </div>
        )}
        
      </div>
    );
  }
}
//export default withStyles(styles)(SendWireTransfer);
export default SendWireTransfer;
