import React, { Component } from "react";
import {
  Grid,
  withStyles,
  Paper,
 // CircularProgress,
  Hidden,
} from "@material-ui/core";
import GaugeChart from "react-gauge-chart";
import "../../Styles/gauge-chart.css";
import Gauge from "./Gauge";
//import SignalBar from "./SignalBar";
import Odometer from "./Odometer";
import GoogleMap from "./GoogleMap";
//import {  BatteryFullRounded } from "@material-ui/icons";
import ShareIcon from "@material-ui/icons/Share";
import RefreshIcon from "@material-ui/icons/Refresh";

const mapStyles = {
  position: "absolute",
  top: "-30px",
};
const mapStyles2 = {
  width: "875px",
  height: "100%",
  position: "absolute",
};
const mapStyles3 = {
  position: "absolute",
  top: "-12px",
};
const styles = (theme) => ({
  paper: {
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "5px",
    border: "solid 1px #f1f1f1",
    minHeight: "175px",
    padding: "20px 15px",
  },
  smallPaper: {
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "5px",
    border: "solid 1px #f1f1f1",
    minHeight: "124px",
    padding: "10px 15px",
  },
  paper2: {
    position: "relative",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    height: "315px",
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  circle: {
    strokeLinecap: "round",
  },
});

class VehicleDetails extends Component {
  render() {
    const { classes } = this.props;
    let data = this.props.carDetails;
    var carData = this.props.moreDetails;
    let bat;
    
    
    if (data !== undefined) {
      bat = data.vehicleDetails.externalBattery.split(" ");
      bat = parseFloat(bat[0]);
      //satellite = data.vehicleDetails.satelites;
      //tracker = data.vehicleDetails.internalBattery
     
    }

    return (
      <>
      {data === undefined ? (
        <div style={{ background: "#ffffff", padding: "35px" }}>
          <GoogleMap style={mapStyles2}/>
        </div>
         
        ): (
      <div style={{ background: "#ffffff", padding: "35px" }}>
        {/* <button className="BackButton" onClick={() => this.props.handleClose()}>
          <img src="/icon/back.svg" alt="back" />
          <span>Back</span>
        </button> */}
        
        <div className="ContentHeader">
          <div style={{ display: "flex" }}>
            <span
              style={{ marginRight: "75px" }}
              className="MainContentHeader Bold"
            >
              {" "}
              {data.Make} {data.Model}
            </span>
            <div
              className={
                data.vehicleDetails.VStatus === "Moving"
                  ? "InProgress"
                  : data.vehicleDetails.VStatus === "Idling"
                  ? "Idle"
                  : "Completed"
              }
              style={{ float: "right" }}
            >
              {data.vehicleDetails.VStatus}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div>
              {" "}
              <span className="MainContentSubheader">{data.NumberPlate} </span>
            </div>

            <button
              style={{
                display: "flex",
                alignItems: "center",
                color: "#002ad1",
              }}
              className="RedirectButton"
              onClick={() =>
                setTimeout(function () {
                  window.location.reload();
                }, 1000)
              }
            >
              <RefreshIcon style={{ marginRight: "10px" }} />
              Update status
            </button>
          </div>
        </div>
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper
              className={classes.paper}
              
            >
              <div className="DisplayFlex">
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <span style={{ width: "150px" }} className="BlackText">
                        Time
                      </span>
                      <span
                        className="GreyText Bold"
                        style={{ textAlign: "right" }}
                      >
                        {data.vehicleDetails.Time}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span style={{ width: "150px" }} className="BlackText">
                      Current Location
                    </span>
                    <span
                      className="GreyText Bold"
                      style={{ textAlign: "right" }}
                    >
                      {data.vehicleDetails.Location}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    <ShareIcon
                      style={{ color: "#f58730", marginRight: "10px" }}
                    />
                    <div>
                      <button
                        className="RedirectButton"
                        style={{
                          textAlign: "right",
                          display: "block",
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        Share live location
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{ width: "150px", textAlign: "left" }}
                      className="BlackText"
                    >
                      Longitude
                    </span>
                    <span
                      className="GreyText Bold"
                      style={{ textAlign: "right" }}
                    >
                      {data.vehicleDetails.Longitude}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{ width: "150px", textAlign: "left" }}
                      className="BlackText"
                    >
                      Latitude
                    </span>
                    <span
                      className="GreyText Bold"
                      style={{ textAlign: "right" }}
                    >
                      {data.vehicleDetails.Latitude}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
            <Hidden mdDown>
              <Paper className={classes.paper2} style={{ minHeight: "180px" }}>
                <GoogleMap
                  carPlate={data.NumberPlate}
                  data={data.vehicleDetails}
                  styles={mapStyles}
                  handlePopUp={null}
                  handleClick={null}
                />
              </Paper>
            </Hidden>
            <Hidden mdUp>
              <Paper className={classes.paper2} style={{ minHeight: "180px" }}>
                <GoogleMap
                  carPlate={data.NumberPlate}
                  data={data.vehicleDetails}
                  styles={mapStyles3}
                  handlePopUp={null}
                  handleClick={null}
                />
              </Paper>
            </Hidden>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper className={classes.paper} style={{ paddingTop: "20px" }}>
              <div>
                <div>
                  <span className="CardTitle">Live Speedometer</span>
                </div>
                <div className="FleetGauge" key={data.NumberPlate}>
                  <Gauge speed={data.vehicleDetails.Speed} />
                </div>
                {/* <div style={{ textAlign: "center", fontSize: "12px" }}>
                  <span>KMPH</span>
                </div> */}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper className={classes.paper}>
              <div>
                <div style={{ marginLeft: "10px" }}>
                  <span className="CardTitle">Fuel levels</span>
                </div>
                <div className="FleetGauge" key={data.NumberPlate}>
                  <GaugeChart
                    id="gauge-chart1"
                    style={{ width: "200px", height: "190" }}
                    arcsLength={[0.3, 0.5, 0.2]}
                    colors={["rgb(0, 42, 209)", "rgba(0, 42, 209, 0.1)"]}
                    percent={data.vehicleDetails.FuelLevel / 100}
                    needleColor="#FFFFFF"
                    needleBaseColor="#FFFFFF"
                    textColor="black"
                    arcWidth={0.1}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper className={classes.paper}>
              <div style={{ marginLeft: "35px" }}>
                <span className="CardTitle">Car battery</span>
              </div>
              <div className="FleetGauge" key={data.NumberPlate}>
                <GaugeChart
                  id="gauge-chart2"
                  style={{ width: "200px", height: "190px !important" }}
                  nrOfLevels={5}
                  arcsLength={[0.3, 0.5, 0.2]}
                  colors={["rgb(0, 42, 209)", "rgba(0, 42, 209, 0.1)"]}
                  percent={bat / 100}
                  needleColor="#FFFFFF"
                  needleBaseColor="#FFFFFF"
                  textColor="black"
                  formatTextValue={(value) => value + " v"}
                  arcWidth={0.1}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.smallPaper} style={{ height: "100px" }}>
              <div style={{ width: "180px" }}>
                <div>
                  <span className="CardTitle">Odometer</span>
                </div>
                <div  key={data.carReg}>
                  <Odometer data={carData} /> {" "}
                  
                </div>
              </div>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.smallPaper}>
              <div style={{ width: "170px" }}>
                <div>
                  <span className="CardTitle">Satellites</span>
                </div>
                <div key={data.carReg}>
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "rgba(0, 42, 209, 0.1)",
                      height: "70px",
                      width: "70px",
                      marginTop: "10px",
                      margin: "0 auto",
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        left: "24px",
                        top: "24px",
                      }}
                    >
                      {satellite}
                    </span>
                    <CircularProgress
                      variant="determinate"
                      value={40}
                      classes={{
                        circle: classes.circle,
                      }}
                      style={{
                        color: "black",
                        marginTop: "20px",
                        position: "relative",
                        top: "-39px",
                      }}
                      size={70}
                      thickness={2}
                    />
                  </div>
                </div>
              </div>
            </Paper>
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper className={classes.smallPaper}>
              <div>
                <span className="CardTitle">Tracker Status</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "15px",
                }}
              >
                <div>
                  <SignalBar signal={2} bars="one" />
                  <div
                    style={{
                      color: "rgba(37, 37, 37, 0.5)",
                      fontSize: "11px",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    GSMA
                  </div>
                </div>

                <div>
                 
                  <BatteryFullRounded
                    fontSize="large"
                    style={{
                      transform: "rotate(90deg)",
                      fontSize: "3rem",
                    }}
                  />
                  <span
                    style={{
                      color: "rgba(37, 37, 37, 0.5)",
                      fontSize: "11px",
                      marginLeft: "-40px",
                    }}
                  >
                    Battery
                  </span>
                </div>
              </div>
            </Paper>
          </Grid> */}
        </Grid>
      </div>
        )}
        </>
   );
  }
}

export default withStyles(styles)(VehicleDetails);
