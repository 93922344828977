import React, { Component } from "react";
import { Grid, withStyles, Paper } from "@material-ui/core";
import SendMoneyMpesa from "./SendMoneyMpesa";
import SendPayPal from "./SendPaypal";
import SendWireTransfer from "./SendWireTransfer";

const styles = (theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px #f5f9fe",
    backgroundColor: "#f5f9fe",
    textAlign: "center",
    color: "rgba(37, 37, 37, 0.7)",
    cursor: "pointer",
    "&:hover": {
      border: "solid 2px #002ad1",
    },
    "&:focus": {
      border: "solid 2px #002ad1",
    },
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

class SendMoney extends Component {
  constructor() {
    super();
    this.state = {
      view: 0,
      data: [],
      investment:[]
    };
    this.handleView = this.handleView.bind(this);
  }

  handleView(view) {
    this.setState({ view: view });
  }
  componentDidMount(){
    var data = this.props.data;
    //console.log(data);
    this.setState({ data: data.accountDetails});
    var accounts = data.accountDetails ;
    var filteredAccount = accounts.filter(
      (account) => account.AccountType === "INVESTMENT"
    );
    this.setState({
      investment: filteredAccount
    });
  }

  render() {
    const { classes } = this.props;
    var account = this.state.investment[0];
    if (account !== undefined) {
      var curr = account.AccountCurrency;
    }
  
    return (
      <div className="Content">
        {this.state.view === 0 && (
          <div>
            <div className="ButtonPadding">
              <button
                className="BackButton"
                onClick={() => {
                  this.state.view === 0
                    ? this.props.handleView(0)
                    : this.handleView(0);
                }}
              >
                <img src="/icon/back.svg" alt="back" />
                <span>Back</span>
              </button>
            </div>
            <div className="ContentSpace">
              <div className="PaymentsView">
                <div
                  className="ContentHeader"
                  style={{ textAlign: "center", marginBottom: "35px" }}
                >
                  <span
                    className="MainContentHeader Bold"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    Send money from your main account or income account
                  </span>
                  <span className="MainContentSubheader">
                    Select from the various options below to proceed. You can transfer funds from one of your accounts 
                    to another or from your account to another client's account
                  </span>
                </div>
                <Grid container spacing={2}>
                  <Grid
                   // style={{ maxWidth: "30%" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                  >
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      //onClick={() => this.handleView(2)}
                      onClick={curr === 'KES' ?  () => this.handleView(2) : null}
                    >
                      <div className="PaymentMethod">
                        <img
                          src="/icon/mpesa.svg"
                          alt="MPESA"
                          style={{ marginTop: "10px" }}
                        />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        ></span>
                        <span className="GreyText">
                          Send money to yourself(one of your java accounts)
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid
                    //style={{ maxWidth: "30%" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                  >
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={curr === 'USD' ?  () => this.handleView(2) : null}
                    >
                      <div className="PaymentMethod">
                        <img
                          src="/icon/Paypal.svg"
                          alt="paypal"
                          style={{ marginTop: "10px" }}
                        />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        ></span>
                        <span className="GreyText">
                          Send money to another Java self drive user 
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                  
                </Grid>
              </div>
            </div>
          </div>
        )}
        {this.state.view === 1 && <SendWireTransfer handleView={this.handleView} />}
        {this.state.view === 2 && (
          <SendMoneyMpesa accounts={this.state.data} handleView={this.handleView} />
        )}
        {this.state.view === 3 && <SendPayPal inv={this.state.investment} handleView={this.handleView} />}
      </div>
    );
  }
}

export default withStyles(styles)(SendMoney);
