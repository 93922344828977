import React, { Component } from "react";
import { Grid, withStyles, Paper } from "@material-ui/core";
import CashOutMpesa from "./CashOutMpesa";
import CashOutPaypal from "./CashOutPaypal";
import CashOutWallet from "./CashOutWallet";
import { getRequest } from "../../Services/FetchFunctions";
const styles = theme => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px #f5f9fe",
    backgroundColor: "white",
    textAlign: "center",
    color: "rgba(37, 37, 37, 0.7)",
    cursor: "pointer",
    "&:hover": {
      border: "solid 2px #002ad1"
    },
    "&:focus": {
      border: "solid 2px #002ad1"
    }
  },
  root: {
    maxWidth: 300,
    flexGrow: 1
  }
});

class CashOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      view: 0,
      investmentAccount:[]
    };

    this.handleView = this.handleView.bind(this);
  }
  componentDidMount(){
    this.getAccounts();
  }
  getAccounts(){
    const url = "client/account/get-accounts";
    getRequest(url)
      .then((resp) => {
        var accounts = resp.data.List;
        var filteredAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        this.setState({
          investmentAccount: filteredAccount
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
}
  handleView(view) {
    this.setState({ view: view });
  }

  render() {
    const { classes } = this.props;
    var account = this.state.investmentAccount[0];
    if (account !== undefined) {
      var curr = account.AccountCurrency;
    }
    console.log(curr);
    return (
      <div>
        {this.state.view === 0 && (
          <div>
            <div className="ButtonPadding">
              <button
                className="BackButton"
                onClick={() => {
                  this.state.view === 0
                    ? this.props.handleView(0)
                    : this.handleView(0);
                }}
              >
                <img src="/icon/back.svg" alt="back" />
                <span>Back</span>
              </button>
            </div>
            <div className="ContentSpace">
              <div style={{ width:'60%', margin:'0 25%' }} className="PaymentsView">
                <div
                  className="ContentHeader"
                  style={{ textAlign: "center", marginBottom: "35px" }}
                >
                  <span
                    className="MainContentHeader Bold"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    Cash out Investment Kit
                  </span>
                  <span className="MainContentSubheader">
                  This feature is used to transfer funds externally, using the options provided below. Either to yourself or 
                 another person.
                  </span>
                </div>
                <div>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={curr === 'KES' ?  () => this.handleView(2) : null}
                    >
                      <div className="PaymentMethod">
                        <img
                          src="/icon/mpesa.svg"
                          alt="MPESA"
                          style={{ marginTop: "10px" }}
                        />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        ></span>
                        <span className="GreyText">
                          Send money to your mobile money MPESA account
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={curr === 'USD' ?  () => this.handleView(3) : null}
                    >
                      <div className="PaymentMethod">
                        <img
                          src="/icon/Paypal.svg"
                          alt="paypal"
                          style={{ marginTop: "10px" }}
                        />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        ></span>
                        <span className="GreyText">
                          Send money directly to your Paypal account
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "174px" }}
                      onClick={() => this.handleView(4)}
                    >
                      <div className="PaymentMethod">
                        <img src="/icon/entypo_wallet.svg" alt="wallet" />
                        <span
                          className="Bold"
                          style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "10px"
                          }}
                        >
                          Wallet
                        </span>
                        <span className="GreyText">
                          Move funds to your wallet account
                        </span>
                      </div>
                    </Paper>
                  </Grid> */}
                </Grid>
                </div>
               
              </div>
            </div>
          </div>
        )}
        {this.state.view === 2 && <CashOutMpesa phone={this.props.phone} inv={this.state.investmentAccount} handleView={this.handleView} />}
        {this.state.view === 3 && (
          <CashOutPaypal inv={this.state.investmentAccount} handleView={this.handleView} />
        )}
        {this.state.view === 4 && (
          <CashOutWallet handleView={this.handleView} />
        )}
      </div>
    );
  }
}
export default withStyles(styles)(CashOut);
