import React, { Component } from "react";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import { getRequest } from "../../Services/FetchFunctions";

class PayPalRedirect extends Component {
  constructor() {
    super();
    this.state = {
      name:'',
      successResponse: "00",
      errorMessage:"",
      message:"Payment done successfullly",
      paymentFee:"0.50",
      amount:"1.00",
      currency: "USD",
      transactionRef:"QOIIEOD&8798",
      paymentData: []
    };
  }
  componentDidMount() {
    
    var searchParams = new URLSearchParams(window.location.search);
    var payment = searchParams.get("paymentId");
    var token = searchParams.get("token");
    var payer = searchParams.get("PayerID");
    
    const url = `external/paypal-confirm?paymentId=${payment}&token=${token}&PayerID=${payer}`;
    getRequest(url)
      .then((resp) => {
        console.log(resp);
        this.setState({ successResponse: resp.data.Status,paymentData: resp.data,paymentFee: resp.data.Fees, amount: resp.data.Amout,
        currency:resp.data.Currency, message:resp.data.Message });
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({ errorMessage: err.response.data.Message });
      });
    //https://stage-backend.javaselfdrive.com/api/v1/external/paypal-confirm?paymentId=PAYID-MDD3C7I4M898685YD601463E&token=EC-33398269JN026684M&PayerID=4JKY9D5XTTNW6
    //https://stage-backend.javaselfdrive.com/api/v1/external/paypal-confirm?paymentId=PAYID-MDD3C7I4M898685YD601463E&token=EC-33398269JN026684M&PayerID=4JKY9D5XTTNW6
    //paypal-confirm?paymentId=PAYID-MDDOSII1S9735306G950000X&token=EC-6JW82758S5521545U&PayerID=4JKY9D5XTTNW6
  }
  render() {
    return (
      <div>
        <div className="ContainerApp">
          <TopBar />
          <div className="MainBody">
            <div className="Menu">
              <Menu />
            </div>
            <div className="Content">
              <div className="ContentHeader">
                <span>Back</span>
              </div>
              <div className="ContentSpace">
                <div style={{width: "495px" }} className="PersonalInfo PaymentsForm">
                 { this.state.successResponse !== "00" ? (
                   <>
                   <div className="PaymentResponse">
                    <img style={{height: "50px" }} src="/icon/error.svg" alt="error" />
                  </div>
                  <div
                    className="ContentHeader"
                    style={{ textAlign: "center", marginBottom: "35px" }}
                  >
                    <span
                      className="MainContentHeader Bold"
                      style={{ display: "block", marginBottom: "10px" }}
                    >
                      Transaction Failed
                    </span>
                    <div style={{ display: "block", marginBottom: "10px" }}>
                    <span style={{marginBottom: "20px" }} className="BlueBold">KES 10,000.00</span>
                    <span className="errorDesc">
                      {this.state.errorMessage}
                    </span>
                    </div>
                    
                    
                  </div>
                   </>
                 ) : (
                   <>
                   <div className="PaymentResponse">
                   <img style={{height: "75px" }} src="/icon/successful.svg" alt="success" />
                 </div>
                 <div
                   className="ContentHeader"
                   style={{ textAlign: "center", marginBottom: "35px" }}
                 >
                   <span
                     className="MainContentHeader Bold"
                     style={{ display: "block", marginBottom: "10px" }}
                   >
                     Transaction successful
                   </span>
                   <div >
                   <span
                     className="MainContentSubheader"
                     style={{ display: "block", marginBottom: "10px" }}
                   >
                     {this.state.message}
                   </span>
                   </div>
                 </div>
                 <div style={{ display: "block", marginBottom: "10px",textAlign:'center' }}>
                    <span style={{marginBottom: "20px" }} className="BlueBold">{this.state.currency} {this.state.amount}</span>
                    <span style={{ color:"rgba(37, 37, 37, 0.7)", marginBottom: "10px" }}>
                    Transferred to your investment account. 
                   Transaction fee {this.state.paymentFee}
                    </span>
                    <span className="goodEffort">
                    Keep up the great effort! We’ll advice you once your account reaches maturity
                    </span>
                    </div>
                 </>
                 )} 
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PayPalRedirect;
