import React, { Component } from "react";
import GaugeChart from "react-gauge-chart";
import '../../Styles/gauge-chart.css'

class Gauge extends Component {
  render() {  
    return (
      <GaugeChart
        id="gauge-chart"
        style={{ width: "200px", height:"190px !important",fontSize:'15px' }}
        nrOfLevels={12}
        colors={["#F05050", "#FFFFFF"]}
        percent={this.props.speed/100}
        needleColor="#FFFFFF"
        needleBaseColor="#FFFFFF"
        textColor="#000000"
        formatTextValue = { (value) => value + ' KM/H' }
        arcWidth={0.1}
      />
    );
  }
}

export default Gauge;