import React, { Component } from "react";
import LandingPageFooter from "../../Components/LandingPage/Footer";
import AppMenu from "../../Components/LandingPage/menu";
import { Hidden } from "@material-ui/core";
import ShowroomFilter from "../../Components/LandingPage/ShowroomFilter";
import { getRequest } from "../../Services/FetchFunctions";
import ShowroomCar from "../../Components/LandingPage/ShowroomCar";
import CarDetails from "../../Components/LandingPage/CarDetails";
import AddToInvestment from "../../Components/LandingPage/AddToInvestment";

class Showroom extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
      mobileOpen: false,
      view: 0,
      availableCars: [],
      rate: 107.61,
      selectedCar: []
    };
    this.handleSelectedCar = this.handleSelectedCar.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleView = this.handleView.bind(this);
  }

  componentDidMount() {
    this.handleShowroomFetch();
  }

  handleShowroomFetch() {
    getRequest("service/sale/list")
      .then(response => {
        this.setState({ availableCars: response.data.List });
      })
      .catch(err => console.log(err));
  }

  handleDrawerToggle(mobile) {
    if (mobile === "mobile") {
      this.setState({ mobileOpen: !this.state.mobileOpen, open: false });
    } else {
      this.setState({ open: !this.state.open, mobileOpen: false });
    }
  }

  handleSelectedCar(car, view) {
    getRequest( "service/sale/vehicle/" + car)
      .then(response => {
        this.setState({ selectedCar: response.data.Data, view: view });
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      })
      .catch(err => console.log(err));
  }

  handleView(view) {
    this.setState({ view: view });
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  handleDisplay(view) {
    switch (view) {
      case 0: {
        return (
          <div className="ShowroomContainer">
            <div className="ShowroomHeader">
              <span className="LandingPageHeader" style={{ fontWeight: "800" }}>
                Buy or sell your car with confidence
              </span>
              <img src="/icon/headerImg.svg" alt="" />
              <br />
              <span
                className="LandingPageDescription"
                style={{ display: "inline-block", fontSize: "22px" }}
              >
                Invest in high quality, low mileage vehicles
              </span>
            </div>
            <div className="Showroom">
              <div className="ShowroomFilter">
                <Hidden smUp implementation="css">
                  <button
                    className="BackButton"
                    style={{ color: "#252B33" }}
                    onClick={() => this.handleDrawerToggle("mobile")}
                  >
                    <img
                      src="/icon/filter1.svg"
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Filter
                  </button>
                </Hidden>
              </div>
              <div className="ShowroomContent">
                <ShowroomFilter
                  handleDrawerToggle={this.handleDrawerToggle}
                  open={this.state.open}
                  mobileOpen={this.state.mobileOpen}
                />
                <ShowroomCar
                  availableCars={this.state.availableCars}
                  handleSelectedCar={this.handleSelectedCar}
                  rate={this.state.rate}
                />
              </div>
            </div>
          </div>
        );
      }
      case 1: {
        return (
          <CarDetails
            selectedCar={this.state.selectedCar}
            handleView={this.handleView}
            rate={this.state.rate}
          />
        );
      }
      case 2: {
        return (
          <AddToInvestment
            selectedCar={this.state.selectedCar}
            handleView={this.handleView}
          />
        );
      }
      default:
        return (
          <div className="ShowroomContainer">
            <div className="ShowroomHeader">
              <span className="LandingPageHeader" style={{ fontWeight: "800" }}>
                Buy or sell your car with confidence
              </span>
              <img src="/icon/headerImg.svg" alt="" />
              <br />
              <span
                className="LandingPageDescription"
                style={{ display: "inline-block", fontSize: "22px" }}
              >
                Invest in high quality, low mileage vehicles
              </span>
            </div>
            <div className="Showroom">
              <div className="ShowroomFilter">
                <Hidden smUp implementation="css">
                  <button
                    className="BackButton"
                    style={{ color: "#252B33" }}
                    onClick={() => this.handleDrawerToggle("mobile")}
                  >
                    <img
                      src="/icon/filter1.svg"
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Filter
                  </button>
                </Hidden>
              </div>
              <div className="ShowroomContent">
                <ShowroomFilter
                  handleDrawerToggle={this.handleDrawerToggle}
                  open={this.state.open}
                  mobileOpen={this.state.mobileOpen}
                />
                <ShowroomCar
                  availableCars={this.state.availableCars}
                  handleSelectedCar={this.handleSelectedCar}
                  rate={this.state.rate}
                />
              </div>
            </div>
          </div>
        );
    }
  }

  render() {
    return (
      <div>
        <AppMenu />
        {this.handleDisplay(this.state.view)}
        <LandingPageFooter />
      </div>
    );
  }
}

export default Showroom;
