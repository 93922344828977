import React, { Component } from "react";
import AppMenu from "../../Components/LandingPage/menu";
import { Grid } from "@material-ui/core";
import LandingPageFooter from "../../Components/LandingPage/Footer";

export default class Support extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: "",
      faqs: [
        {
          question: "Are prices negotiable?",
          ans: "We begin by registering and signing up for an account, and visiting the explore deals page, to view all avaiable investment deals with Java Self Drive.",
        },
        {
          question: "Can I pay in installments?",
          ans: "We begin by registering and signing up for an account, and visiting the explore deals page, to view all avaiable investment deals with Java Self Drive.",
        },
        {
          question: "Who owns the car after adding to portfolio?",
          ans: "We begin by registering and signing up for an account, and visiting the explore deals page, to view all avaiable investment deals with Java Self Drive.",
        },
        {
          question: "Are prices negotiable?",
          ans: "We begin by registering and signing up for an account, and visiting the explore deals page, to view all avaiable investment deals with Java Self Drive.",
        },
        {
          question: "Does Java self drive provide warranties?",
          ans: "We begin by registering and signing up for an account, and visiting the explore deals page, to view all avaiable investment deals with Java Self Drive.",
        },
      ],
    };
  }
  render() {
    return (
      <div>
        <AppMenu />
        <div className="SupportContainer">
          <div style={{ textAlign: "center" }}>
            <p className="LandingPageHeader">How can we help you today?</p>
            <p style={{ width: "100%" }} className="LandingPageSubHeader">
              Need more info? Get in touch with our teams or check the FAQ
              section below
            </p>
          </div>
        </div>
        <Grid
          container
          spacing={2}
          style={{ position: "relative", top: "-145px",height:'800px' }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="SupportForm">
              <div
                style={{
                  width: "356px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <span className="MainContentTitle">
                  We'd love to hear from you
                </span>
                <div style={{ marginBottom: "32px" }}>
                  <span className="MainContentSubheader">
                    Talk to us about your issue or feedback so we can help you
                    more quickly
                  </span>
                </div>
              </div>

              <label>Name</label>
              <input
                type="text"
                value={this.state.name}
                placeholder="e.g Jon Smith"
              />
              <label>Phone Number</label>
              <input
                type="text"
                value={this.state.phone}
                placeholder="e.g 254712345678"
              />
              <label>Email address</label>
              <input
                type="text"
                value={this.state.email}
                placeholder="e.g email@domain.com"
              />
              <label>Message</label>
              <textarea
                type="text"
                value={this.state.message}
                placeholder="Tell us more about your need or issue"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div
              style={{ width: "250px", textAlign: "left", margin: "0 auto" }}
            >
              <p className="BlackText2">Support Team</p>
              <hr style={{ margin: "5px 0" }} className="blueBorder" />
              <div style={{ marginBottom: "16px" }}>
                <p style={{ margin: "0" }} className="GreyText">
                  contactsupport@javaselfdrive.com
                </p>
                <p style={{ margin: "5px 0" }} className="GreyText">
                  (+254) - 701151932
                </p>
              </div>
              <p className="BlackText2">Contact Information</p>
              <hr style={{ margin: "5px 0" }} className="blueBorder" />
              <div style={{ marginBottom: "16px" }}>
                <p style={{ margin: "0" }} className="GreyText">
                  javaorientkenyalimited@gmail.com
                </p>
                <p style={{ margin: "5px 0" }} className="GreyText">
                  (+254) - 701151932
                </p>
              </div>
              <p className="BlackText2">Physical Address</p>
              <hr style={{ margin: "5px 0" }} className="blueBorder" />
              <div style={{ marginBottom: "16px" }}>
                <p style={{ margin: "0" }} className="GreyText">
                  Nyali - Mombasa, Kenya
                </p>
                <p style={{ margin: "5px 0" }} className="GreyText">
                  BaoBab (Quick - fill) Petrol Station, Nyali Branch.
                </p>{" "}
                <p style={{ margin: "5px 0" }} className="GreyText">
                  P.O BOX 775 - 80109
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="Faqs LandingPagePadding">
         <p className="LandingPageHeader">Frequently Asked Questions</p>
         {this.state.faqs.map((faq,index)=>(
             <div key={index} style={{width:'680px'}}>
                 <p style={{ fontWeight:'900',fontSize:'18px'}} className="BlackText2">{faq.question}</p>
                 <p  style={{ textAlign:'left'}} className="LandingPageDescription">{faq.ans}</p>
             </div>
         ))}
            
        </div>
        <LandingPageFooter />
      </div>
    );
  }
}
