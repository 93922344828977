import React, { Component } from "react";
import { withRouter } from "react-router-dom";
//import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

class QuickActions extends Component {
  render() {
    return (
      <div
        className="Cards"
        style={this.props.payments ? { padding: "0px" } : null}
      >
        {!this.props.payments ? (
          <div>
            <span className="CardTitle">Quick actions</span>
          </div>
        ) : null}
        <button
          className="ContentWhiteButton"
          style={this.props.payments ? { marginTop: "0px" } : null}
          onClick={
            this.props.payments
              ? () => this.props.handleView(1)
              : () => {
                  this.props.history.push({
                    pathname: "/Payments",
                    view: 1,
                  });
                  localStorage.setItem("menu", "Payments");
                }
          }
        >
          <img src="/icon/walletTopup.svg" alt="wallet" />
          <span>Top-up Investment kit</span>
        </button>
        <button
          className="ContentWhiteButton"
          onClick={
            this.props.payments
              ? () => this.props.handleView(3)
              : () => {
                  this.props.history.push({
                    pathname: "/Payments",
                    view: 0,
                  });
                  localStorage.setItem("menu", "Payments");
                }
          }
        >
          <img src="/icon/sendMoney.svg" alt="send" />
          <span>Send money</span>
        </button>
        {this.props.payments ? (
          <button  onClick={
            this.props.payments
              ? () => this.props.handleView(4)
              : () => {
                  this.props.history.push({
                    pathname: "/Payments",
                    view: 3,
                  });
                  localStorage.setItem("menu", "Payments");
                }
          } className="ContentWhiteButton">
            <img className="walletIcon" src="/icon/payments.svg" alt="wallet" />
           
            {/* <AccountBalanceWalletOutlinedIcon className="walletIcon" style={{ color:'#002ad1',marginRight:'10px', "&:hover": {color: 'white'}}} />  */}
            <span>Cash out</span>
          </button>
        ) : null}
      </div>
    );
  }
}

export default withRouter(QuickActions);
