import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  Modal,
  Backdrop,
  MuiThemeProvider,
} from "@material-ui/core";
import CustomSnackbar from "../SharedComponents/CustomSnackbar";
//import Donut from "../SharedComponents/Donut";
import { Doughnut } from 'react-chartjs-2';


  
const stylesModal = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "inherit",
  },
  container: {
    minHeight: 450,
    overflowX: "hidden",
  },
  selectIcon: {
    display: "none",
  },
  backdrop: {
    backgroundColor: "rgba(37, 43, 51, 0.5)",
  },
});
const themeModal = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5)",
        backdropFilter: "blur(2px)",
      },
    },
  },
});

class UnlockAccount extends Component {
  constructor() {
    super();
    this.state = {
      otp: "",
      lockAmount: "50000",
      lockTitle: "",
      selectedCurrency: "KES",
      view: 0,
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      errorMessage: "",
    };
    
  }
  componentDidMount() {
    console.log(this.props.lockStatus);
  }
  
  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  render() {
    const { classes } = this.props;
    let CurrencyFormat = require("react-currency-format");
    let percent = this.props.lockStatus.PerToTarget;
    const plugins = [{
      beforeDraw: function(chart) {
       var width = chart.width,
           height = chart.height,
           ctx = chart.ctx;
           ctx.restore(); 
           var fontSize = (height / 160).toFixed(2);
           ctx.font = fontSize + "em sans-serif";
           ctx.textBaseline = "top";
           var text = percent + "%",
           textX = Math.round((width - ctx.measureText(text).width) / 2),
           textY = height / 2;
           ctx.fillText(text, textX, textY);
           ctx.save();
      } 
    }];
    let other = percent >= 100 ? 0 : 100 - percent;
    const data = {
      datasets: [
        {
          data: [percent, other],
          backgroundColor: [
            '#002ad1',
            '#f5f9fe'
          ],
          borderWidth: 1,
        },
      ],
    };
    return (
      <div>
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <MuiThemeProvider theme={themeModal}>
          <Modal
            className={classes.modal}
            open={this.props.setUnlockOpen}
            onClose={() => this.props.handleUnlock()}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <div className={classes.paper}>
              <div style={{ width: "760px" }} className="ModalPaper">
                <div className="ModalHeader">
                  <div>
                    <button
                      className="BackButton"
                      style={{ float: "right", marginBottom: "0px" }}
                      onClick={() => this.props.handleUnlock()}
                    >
                      <img src="./icon/close.svg" alt="x" />
                    </button>
                  </div>
                  <div
                    style={{ alignItems: "center", display: "inline-block" }}
                  >
                    <span
                      className="MainContentHeader"
                      style={{ fontSize: "20px", marginBottom: "15px" }}
                    >
                      Unlock investment account
                    </span>

                    <br />
                    <div style={{ textAlign: "left" }} className="WarningBar">
                      <img src="/icon/warning.svg" alt="warning" />
                      <span>
                        You'll be required to contact customer experience to
                        unlock account. Else you can view details below or
                        continue locking the account untill maturity.
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0", textAlign: "center" }}>
                  <span style={{ color: "#002ad1" }}>
                    Account locked up to{" "}
                    <b>
                      {this.props.lockStatus.AccountCurrency}
                      {" "}
                      <CurrencyFormat
                        value={parseFloat(this.props.lockStatus.TargetAmount).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                    </b>
                  </span>
                </div>
                <div className="ModalBox">
                  <span className="MainContentSubtitle">
                    Total value in account
                  </span>
                  <div className="DisplayFlexSpace">
                  <div style={{width:'150px',height:'150px'}}>
                <Doughnut data={data} options={{
                    cutout:'80%',
                }} 
                plugins={plugins} />
            </div>
                  <div style={{width:'350px'}}>
                      <div style={{ display:'flex',marginBottom:'15px',alignItems:'center'}}>
                      <div style={{ backgroundColor:'#002ad1',borderRadius:'50%',height:"10px",width:'10px',content:'none',marginRight:'10px'}}>
                      </div>
                      <span style={{fontWeight:'600'}} className="GreyText">
                      {this.props.lockStatus.AccountCurrency}
                      {" "}
                      <CurrencyFormat
                        value={parseFloat(this.props.lockStatus.TargetAmount).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                      </span>
                      </div>
                      

                      <div style={{display:'block'}}>
                          <span className="GreyText">
                          We advice you continue investing, we’ll notify you once your account reaches maturity.
                          </span>
                      </div>
                  </div>
                  </div>
                  
                </div>
                <div
                      style={{ justifyContent: "center" }}
                      className="ModalFooter"
                    > 
                      <button
                        style={{ width: "300px" }}
                        className="SignUpFormsSubmit"
                        onClick={() => this.props.handleUnlock()}
                      >
                        Continue Locking
                      </button>
                    </div>
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      </div>
    );
  }
}
export default withStyles(stylesModal)(UnlockAccount);
