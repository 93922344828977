import React, { Component } from "react";
import NoData from '../SharedComponents/NoData'; 
class FleetTable extends Component {
  render() {
    
    return (
      <div style={{ maxHeight: "501px", overflowY: "auto" }}>
        {this.props.fleet[0] !== undefined ? (
          <>
          
  {this.props.fleet.map((step, index) => (
          <button
            className={
              step === this.props.selected
                ? "FleetTableRowsSelected"
                : "FleetTableRows"
            }
            key={index}
            onClick={() => this.props.handleSelected(step)}
          >
            <div>
              <div>
                <span className="BlackText">
                  {step.Make} {step.Model}
                </span>
              </div>
              <div style={{ marginTop: "5px"  }}>
                <span className="GreyText">{step.NumberPlate}</span>
              </div>
              <div style={{ marginTop: "5px" }}>
                <span className="GreyText">{step.vehicleDetails.Location}</span>
              </div>
            </div>
           
            
            <div
              className={
                step.vehicleDetails.VStatus === "Moving"
                  ? "InProgress"
                  : step.vehicleDetails.VStatus === "Idling"
                  ? "Idle"
                  : "Completed"
              }
            >
              {step.vehicleDetails.VStatus}
            </div>
            {/* <div style={{display:'block',marginTop:'10px'}}>
            <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height:'35px'
            }}
            className="SignUpFormsSubmit"
            onClick={ () => this.props.handleOpen(this.props.selected)}
          >
            View Details
            
          </button>
            </div> */}
         
        
          </button>
        ))}
          </>
        ) : (
          <NoData title={"No car records yet"}
        subtitle={
          "Visit the get started page and ensure you have added vehicles to your fleet and completed your profile for verification"
        }/>
        )}
        
      </div>
    );
  }
}

export default FleetTable;
